
import React from 'react';
import {makeStyles, Theme} from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Navbar, { NavbarProps } from '../Navbar';

export interface MainLayoutProps {
  hideNavbar?: boolean;
  NavbarProps?: NavbarProps;
}

const useStyles = makeStyles((theme: Theme)=>({
  toolbar: theme.mixins.toolbar
}));

const MainLayout: React.FC<MainLayoutProps> = function(props) {
  const {
    hideNavbar = false,
    NavbarProps = {},
    children
  } = props;

  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });
  const classes = useStyles();

  return (
    <Box display={isSmallScreen ? 'block' : 'flex'} height="100vh">
      {!hideNavbar && <Navbar {...NavbarProps} />}
      <Box flex={1} overflow="auto">
        {!hideNavbar && <div className={classes.toolbar} />}
        {children}
      </Box>
    </Box>
  );
};

export default MainLayout;
