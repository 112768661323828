
import React, { forwardRef, useImperativeHandle } from 'react';
import { PlaceFormProps, PlaceFormRef, PlaceData } from './types';
import validationSchema from './validation-schema';
import { useFormik } from 'formik';
import TextField from '@material-ui/core/TextField';

const PlaceForm: React.ForwardRefRenderFunction<PlaceFormRef, PlaceFormProps> = function(props, ref) {
  const {
    initialValues = {} as PlaceData,
    disabled = false,
    onSubmit = ()=>{}
  } = props;

  const formik = useFormik<PlaceData>({
    initialValues: {
      name: initialValues.name || ''
    },
    validationSchema,
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <TextField
        label="NAME"
        type="text"
        {...formik.getFieldProps('name')}
        error={formik.touched['name'] && !!formik.errors['name']}
        helperText={formik.errors['name']}
        disabled={disabled}
        size="small"
        variant="standard"
      />
    </form>
  );
};

export default forwardRef(PlaceForm);
