
import { createStyles } from '@material-ui/core/styles';

const styles = createStyles({
  dialogPaper: {
    height: '90vh'
  }
});

export default styles;
