
import React from 'react';
import useGlobalConfig from '../../hooks/use-global-config';
import Navbar, { NavbarProps } from '../../components/Navbar';

const NavbarContainer: React.FC<NavbarProps> = function(props) {
  const config = useGlobalConfig();

  return (
    <Navbar
      title={config.title}
      showBackButton={config.showBackButton}
      showLoader={config.loading}
      {...props}
    />
  );
};

export default NavbarContainer;
