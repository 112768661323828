import ApiService from "../api";
import Place from "../../models/Place";
import {momentTrackService} from "../moment-track";
import placeLabelsTemplate from "../../label-templates/place-label";
import placeLabelsSheetTemplate from "../../label-templates/place-label-sheet";
import apiClient from "../api-client";

export default class PlacesService extends ApiService<Place> {
  async generateLabels(
    places: Place[],
    templateType: "single" | "sheet" = "sheet"
  ) {
    let labelsData: any = [];

    places.forEach(place => {
      labelsData.push({
        url: momentTrackService.generateUrl(place.id, "place"),
        iconUrl:
          "https://replenish-icons.s3-us-west-1.amazonaws.com/current_icons/location_icon.svg",
        place_name: place.name,
      });
    });

    let requestData;

    if (templateType === "single") {
      requestData = {
        html: placeLabelsTemplate(labelsData),
        pdf_mode: "portrait",
        pdf_name: "place-labels",
        page_width: "66.675mm",
        page_height: "25.4mm",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
    } else {
      const singleSheetLabelsCount = 30;
      const sheetsCount = Math.ceil(labelsData.length / singleSheetLabelsCount);
      const sheets: any = [];

      for (let i = 0; i < sheetsCount; i++) {
        const startIndex = i * singleSheetLabelsCount;
        const endIndex = startIndex + singleSheetLabelsCount;
        sheets.push(labelsData.slice(startIndex, endIndex));
      }

      requestData = {
        html: placeLabelsSheetTemplate(sheets),
        pdf_mode: "portrait",
        pdf_name: "place-labels",
        page_size: "Letter",
        margin_top: "0mm",
        margin_bottom: "0mm",
        margin_left: "0mm",
        margin_right: "0mm",
        disable_smart_shrinking: null,
      };
    }

    const response = await apiClient.post(
      process.env.REACT_APP_HTML_2_PDF_URL || "",
      requestData
    );
    // await momentTrackService.printPdf(response.data.data?.s3_path, 'Place labels from ordertrack');
    return response.data?.s3_path;
  }
}
