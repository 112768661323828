
import React, { useState, useRef } from 'react';
import { peopleService } from '../../services/api';
import { PersonEditDialogProps } from './types';
import { useNotifier } from '../../providers/NotifierProvider';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PersonForm from '../../components/person-form/PersonForm';

const PersonEditDialog: React.FC<PersonEditDialogProps> = function(props) {
  const { person, onClose, ...dialogProps } = props;
  const personForm = useRef<any>();
  const notify = useNotifier();

  const [loading, setLoading] = useState(false);

  async function handleSave(data: any) {
    try{
      setLoading(true);
      await peopleService.updateById(person.id, data);
      notify('Person updated.');
      onClose && onClose();
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Dialog
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      maxWidth="xs"
      fullWidth
      {...dialogProps}
    >
      <DialogTitle>Edit person profile</DialogTitle>
      <DialogContent>
        <PersonForm
          ref={personForm}
          initialValues={person}
          onSubmit={handleSave}
          disabled={loading}
        />
      </DialogContent>
      <DialogActions>
        <Button disabled={loading} onClick={()=>onClose && onClose()}>Cancel</Button>
        <Button disabled={loading} onClick={()=>personForm.current?.submitForm()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default PersonEditDialog;
