
import EventEmitter from 'events';
import { MomentTrackIdTypes, MomentTrackIdLengths } from './types';
import axios from 'axios';

export default class MomentTrackService extends EventEmitter {
  constructor(private urlPrefix = '3hd.us/'){
    super();
  }

  parseId(id: string) {
    const parsedId = id.includes('/') ? id.split("/")[1] : id;
    const type = MomentTrackIdLengths[parsedId.length];

    if(!type)
      throw new Error('Could not identify type of this id.');
      
    return {
      id: parsedId,
      url: this.urlPrefix + parsedId,
      type
    };
  }

  generateId(value: number|string, idType: MomentTrackIdTypes) {
    if(value === undefined || value === null)
      return value;

    return value.toString().padStart(MomentTrackIdLengths[idType], '0');
  }

  generateUrl(value: number|string, idType: MomentTrackIdTypes) {
    const momentTrackId = this.generateId(value, idType);

    if(momentTrackId === undefined || momentTrackId === null)
      return momentTrackId;

    return this.urlPrefix + momentTrackId;
  }

  printPdf(pdfUrl: string, title: string = 'Print job from momenttrack') {
    return axios.post('https://k2vpki1p5i.execute-api.us-west-1.amazonaws.com/prod/printNode', {
      printer_url: "3hd.us/b23JFAFladW8KeD5GxFX9jeTK",
      title,
      contentType: "pdf_uri",
      content: pdfUrl,
      expireAfter: 600,
      passkey: "jaredeggettiscool"
    });
  }
}
