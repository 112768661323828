
import React, { useRef, useCallback, useState } from 'react';
import authService from '../../services/authentication';
import { useNotifier } from '../../providers/NotifierProvider';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import CloseIcon from '@material-ui/icons/Close';
import { Link as RouterLink, RouteComponentProps } from 'react-router-dom';
import AuthenticationForm, { AuthenticationFormRef } from '../../components/authentication-form/AuthenticationForm';
import { makeStyles } from '@material-ui/core/styles';
import { LoginHandler } from './types';
import styles from './styles';

const useStyles = makeStyles(styles);

const LoginPage: React.FC<RouteComponentProps> = function(props) {
  const authForm = useRef<AuthenticationFormRef>(null);
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const isInsideIframe = (()=>{
    try{
      return window.self !== window.parent;
    }catch (e) {
      return true;
    }
  })();

  const handleLogin: LoginHandler = useCallback(async (creds)=>{
    try{
      setLoading(true);
      await authService.login(creds.email, creds.password);
    }
    catch(err){
      notify(err.message);
      setLoading(false);
    }
  }, [notify]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <Paper>
          {loading && <LinearProgress />}
          <Box p={3}>
            <div className={classes.formHeader}>
              <Typography variant="h4">Welcome</Typography>
              <Typography variant="body2" color="textSecondary">Enter credentials to access the system</Typography>
            </div>

            <div className={classes.formContainer}>
              <AuthenticationForm
                ref={authForm}
                onSubmit={handleLogin}
                disabled={loading}
              />
            </div>

            <Typography className={classes.forgotLink}
              variant="subtitle2"
              align="right"
              gutterBottom
            >
              <Link
                to="/forgot-password"
                component={RouterLink}
              >
                Forgot password?
              </Link>
            </Typography>

            <Button className={classes.submitBtn}
              onClick={()=>authForm.current?.submitForm()}
              disabled={loading}
              size="large"
              variant="contained"
              color="primary"
              fullWidth
            >Login</Button>
          </Box>
        </Paper>

        {isInsideIframe && (
          <Box mt={2} textAlign="center">
            <IconButton disabled={loading} onClick={()=>window.parent.postMessage({type: 'CLOSE', data: 'Unknown origin.'}, '*')}>
              <CloseIcon />
            </IconButton>
          </Box>
        )}
      </Container>
    </div>
  );
};

export * from './types';
export default LoginPage;
