
import React, { useState, useRef } from 'react';
import BinFamily from '../../models/BinFamily';
import Place from '../../models/Place';
import { momentTrackService } from '../../services/moment-track';
import { binFamiliesService } from '../../services/bin-families';
import { binsService } from '../../services/api';
import { placesService } from '../../services/places';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalConfig from '../../hooks/use-global-config';
import { useNotifier } from '../../providers/NotifierProvider';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import QrCodeScanner, { QrCodeScannerRef } from '../../components/QrCodeScanner';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import RatioedBox from '../../components/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
// import ButtonBase from '@material-ui/core/ButtonBase';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ProductIcon from '@ordertrack/base/assets/icons/product.svg';
import PlaceIcon from '@ordertrack/base/assets/icons/place.svg';
import ArrowDownIcon from '@material-ui/icons/ArrowDownward';
import QrScannerIcon from '@material-ui/icons/CropFree';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './styles';

const useStyles = makeStyles(styles);

const MoveBinFamilyPage: React.FC = function() {
  const classes = useStyles();
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const qrCodeScanner = useRef<QrCodeScannerRef>(null);
  const [loading, setLoading] = useState(false);
  const [binFamily, setBinFamily] = useState<BinFamily | null>(null);
  const [place, setPlace] = useState<Place | null>(null);

  useGlobalConfig({
    title: 'Move bin family',
    loading
  });

  async function handleScanResult(result: string) {
    try{
      setLoading(true);

      const idInfo = momentTrackService.parseId(result);
      const id = Number(idInfo.id);

      if(Number.isNaN(id))
        throw new Error('Invalid Qr');

      if(idInfo.type === 'product' || idInfo.type === 'document'){
        const bin = await binsService.fetchById(id);
        setBinFamily(bin.bin_family);
      }
      else if(idInfo.type === 'place'){
        const place = await placesService.fetchById(id);
        setPlace(place);
      }
      else{
        throw new Error('Looks like you scanned a wrong QR code. Scan product QR on the bin or place QR code.');
      }
    }
    catch(err){
      notify(err as Error);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleBinFamilyMove() {
    try{
      if(!binFamily || !place)
        return;

      setLoading(true);
      if(!(await confirm('Are you sure?', `Moving bin family for product "${binFamily?.product.name}" from place "${binFamily?.location.name}" to new place "${place?.name}".`)))
        throw new Error('Cancelled');

      await binFamiliesService.moveFamily(binFamily?.id, place.id);
      notify('Success! Bin family moved to new place.');
      setBinFamily(null);
      setPlace(null);
    }
    catch(err){
      notify(err as Error);
    }
    finally{
      setLoading(false);
    }
  }

  function handleOpenScanner() {
    if(loading)
      return;
    qrCodeScanner.current?.openScanner();
  }

  return (
    <Container maxWidth="xs">
      <Box py={4}>
        <Grid container spacing={2} alignItems="center" justify="center">
          <Grid item xs={8}>
            <Paper elevation={loading ? 0 : 1}>
              {/* <ButtonBase
                style={{ width: '100%' }}
                onClick={()=>qrCodeScanner.current?.openScanner()}
                disabled={loading}
              > */}
                <RatioedBox
                  sizeRatio="1:1"
                  onClick={handleOpenScanner}
                  className={classes.buttonThumbnail}
                >
                  <img
                    src={ ProductIcon }
                    alt="Product icon"
                    className={classes.mainIcon}
                  />

                  {!!binFamily && (
                    <>
                      <IconButton
                        size="small"
                        onClick={ev=>{
                          ev.stopPropagation();
                          setBinFamily(null);
                        }}
                        className={classes.clearButton}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                      <Typography variant="h6" color="primary">
                        {binFamily.product.name}
                      </Typography>
                      <Typography variant="caption">@ {binFamily.location.name}</Typography>
                      <Box pt={2}>
                        <Button
                          size="small"
                          startIcon={<QrScannerIcon />}
                        >
                          Scan again
                        </Button>
                      </Box>
                    </>
                  )}
                  {!binFamily && (
                    <>
                      <Typography variant="h6" gutterBottom>Scan product QR</Typography>
                      <Typography variant="caption" color="textSecondary">
                        Product Qr can be located in front of the bin you want to move.
                      </Typography>
                    </>
                  )}
                </RatioedBox>
              {/* </ButtonBase> */}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            {(!binFamily || !place) && (
              <Box textAlign="center" lineHeight={0}>
                <ArrowDownIcon />
              </Box>
            )}

            {!!binFamily && !!place && (
              <Box textAlign="center" py={2}>
                <Button
                  size="large"
                  color="primary"
                  variant="contained"
                  onClick={handleBinFamilyMove}
                >
                  Move to place
                </Button>
              </Box>
            )}
          </Grid>

          <Grid item xs={8}>
            <Paper elevation={loading ? 0 : 1}>
              {/* <ButtonBase
                style={{ width: '100%' }}
                onClick={()=>qrCodeScanner.current?.openScanner()}
                disabled={loading}
              > */}
                <RatioedBox
                  sizeRatio="1:1"
                  className={classes.buttonThumbnail}
                  onClick={handleOpenScanner}
                >
                  <img
                    src={ PlaceIcon }
                    alt="Place icon"
                    className={classes.mainIcon}
                  />

                  {!!place && (
                    <>
                      <IconButton
                        size="small"
                        onClick={ev=>{
                          ev.stopPropagation();
                          setPlace(null);
                        }}
                        className={classes.clearButton}
                      >
                        <ClearIcon color="error" />
                      </IconButton>
                      <Typography variant="h6" color="primary">
                        {place.name}
                      </Typography>
                      <Box pt={2}>
                        <Button
                          size="small"
                          startIcon={<QrScannerIcon />}
                        >
                          Scan again
                        </Button>
                      </Box>
                    </>
                  )}

                  {!place && (
                    <>
                      <Typography variant="h6" gutterBottom>Scan place</Typography>
                      <Typography variant="caption" color="textSecondary">
                        Place Qr can be located in front of destined place for the bin.
                      </Typography>
                    </>
                  )}
                </RatioedBox>
              {/* </ButtonBase> */}
            </Paper>
          </Grid>
        </Grid>
      </Box>

      <QrCodeScanner
        ref={qrCodeScanner}
        onResult={handleScanResult}
        hidden
      />
    </Container>
  );
};

export default MoveBinFamilyPage;
