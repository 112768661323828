
import React, { useState, useContext, useLayoutEffect, useCallback, useImperativeHandle, useRef, forwardRef, useEffect } from 'react';
import { AsideComponent } from './types';
import clsx from 'clsx';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Portal from '@material-ui/core/Portal';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import layoutContext from '../context';
import styles from './styles';

const generateId = (()=>{
  let currId = 0;
  return ()=>++currId;
})();

const useStyles = makeStyles(styles);

const defaultTrigger = (isCollapsed: boolean) => {
  if(isCollapsed)
    return <IconButton><MenuIcon /></IconButton>;
  
  return <IconButton><MenuOpenIcon /></IconButton>;
};

const Aside: AsideComponent = (props, ref) => {
  const {
    width = 'auto',
    collapsedWidth = 0,
    collapsed = false,
    onTrigger,
    breakpoint = 'sm',
    onBreakpoint,
    trigger = defaultTrigger,
    triggerContainer,
    anchor = 'left',
    variant = 'standard',
    disableClickAwayListener = false,
    noBackdrop = false,
    className

  } = props;

  const contentWrapper = useRef<HTMLDivElement>(null);
  const theme = useTheme();
  const isBelow = useMediaQuery(theme.breakpoints.down(breakpoint), { noSsr: true });
  const [isCollapsed, setIsCollapsed] = useState(collapsed || variant === 'drawer' || isBelow);
  const [rootWidth, setRootWidth] = useState<any>(width);
  const [triggerContainerEl, setTriggerContainerEl] = useState<Element | null>(null);
  const { registerAside } = useContext(layoutContext);

  const isDrawer = variant === 'drawer' || isBelow;
  const showTrigger = trigger !== null && (isCollapsed || isBelow || variant !== 'standard');

  const handleTrigger = useCallback((collapsed: boolean)=>{
    setIsCollapsed(collapsed);
    onTrigger && onTrigger(collapsed);

  }, [onTrigger]);

  useImperativeHandle(ref, ()=>({
    expand: handleTrigger.bind(null, false),
    collapse: handleTrigger.bind(null, true)

  }), [handleTrigger]);

  useLayoutEffect(()=>{
    handleTrigger(collapsed || isDrawer);

  }, [collapsed, isDrawer, handleTrigger]);

  useLayoutEffect(()=>{
    setRootWidth(contentWrapper.current?.offsetWidth || width);

  }, [width]);

  useLayoutEffect(()=>{
    setImmediate(()=>{
      setTriggerContainerEl(typeof triggerContainer === 'function' ? triggerContainer() : triggerContainer || null);
    });
    
  }, [triggerContainer]);

  useLayoutEffect(()=>{
    registerAside(generateId());
  }, [registerAside]);

  useEffect(()=>{
    onBreakpoint && onBreakpoint(isBelow);
  }, [isBelow, onBreakpoint]);

  const classes = useStyles({
    isBelow,
    width,
    rootWidth,
    collapsedWidth,
    anchor,
    floatingTrigger: !triggerContainerEl
  });

  return (
    <>
      {isDrawer && !noBackdrop && (
        <Fade in={!isCollapsed}>
          <div className={classes.backdrop} />
        </Fade>
      )}
      <ClickAwayListener onClickAway={isDrawer && !disableClickAwayListener ? ()=>handleTrigger(true) : ()=>{}}>
        <aside
          className={clsx(classes.root, {
            [classes.drawer]: isDrawer,
            [classes.collapsed]: isCollapsed
          })}
        >
          <div
            ref={contentWrapper}
            className={className}

          >{props.children}</div>

          {showTrigger && (
            <Portal container={triggerContainerEl} disablePortal={!triggerContainerEl}>
              <div className={classes.triggerWrapper} onClick={()=>handleTrigger(!isCollapsed)}>
                {typeof trigger === 'function' ? trigger(isCollapsed) : trigger}
              </div>
            </Portal>
          )}
        </aside>
      </ClickAwayListener>
    </>
  );
};

const C = forwardRef(Aside);
C.displayName = 'Aside';
export default C;
