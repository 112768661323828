
import { useState, useEffect } from 'react';
import { globalConfigService, GlobalConfig } from '../../services/global-config';

export default function useGlobalConfig(settings: GlobalConfig = {}) {
  const { title, showBackButton, loading } = settings;
  const [config, setConfig] = useState<GlobalConfig>(globalConfigService.getConfig());

  useEffect(()=>{
    globalConfigService.update({
      title,
      showBackButton,
      loading
    });

  }, [title, showBackButton, loading]);

  useEffect(()=>{
    globalConfigService.on('update', setConfig);
    return ()=>{
      globalConfigService.off('update', setConfig);
    };
  }, []);

  useEffect(()=>{
    return ()=>{
      globalConfigService.reset();
    };
  }, []);

  return config;
}
