
import React, { useState, FormEvent } from 'react';
import authService from '../../services/authentication';
import { useNotifier } from '../../providers/NotifierProvider';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

const InvitePersonPanel: React.FC = function() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotifier();

  async function handleSend(ev: FormEvent) {
    try{
      ev.preventDefault();

      if(!email.trim())
        return;

      setLoading(true);
      const message = await authService.sendInvite(email.trim());
      
      setEmail('');
      notify(message);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Paper>
      <Box p={2}>
        <Typography variant="h6">Send Invite</Typography>
        <Typography variant="subtitle2" color="textSecondary">Add new people</Typography>
      </Box>
      <form onSubmit={handleSend}>
        <Box p={2}>
          <TextField
            type="email"
            color="secondary"
            placeholder="Email address"
            required
            size="small"
            disabled={loading}
            value={email}
            onChange={(ev)=>setEmail(ev.target.value)}
          />
        </Box>
        <Box p={2}>
          {loading && <CircularProgress size={24} color="secondary"/>}
          {!loading && <Button type="submit" variant="outlined" color="secondary" disabled={loading || !email.length}>Send</Button>}
        </Box>
      </form>
    </Paper>
  );
};

export default InvitePersonPanel;
