
import { useState, useEffect } from 'react';
import authService from '../../services/authentication';

export default function useAuthentication() {
  const [authenticationDetails, setAuthenticationDetails] = useState(authService.getAuthenticationDetails());

  useEffect(()=>{
    function updateAuth(){
      setAuthenticationDetails(authService.getAuthenticationDetails());
    }

    authService.on('login', updateAuth);
    authService.on('logout', updateAuth);

    return ()=>{
      authService.off('login', updateAuth);
      authService.off('logout', updateAuth);
    }
  }, []);

  return authenticationDetails;
}