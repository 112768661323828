
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

const Header: React.FC<BoxProps> = props => (
  <Box
    component="header"
    flexBasis={0}
    {...props}
  />
);

export default Header;
