
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { binFamiliesService } from '../../services/bin-families';
import BinFamily from '../../models/BinFamily';

export default function useBinFamilies(): [BinFamily[], boolean, Error|string] {
  const [binFamilies, setBinFamilies] = useState<BinFamily[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchBinFamilies = useCallback(async ()=>{
    try{
      setLoading(true);
      const binFamilies = await binFamiliesService.fetchAll();
      binFamilies.sort((a,b)=>a.product.name.localeCompare(b.product.name));
      
      setMessage(binFamilies.length === 0 ? 'Nothing found.' : '');
      setBinFamilies(binFamilies);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load bin families.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchBinFamilies();

  }, [ fetchBinFamilies ]);

  useEffect(()=>{
    function addBinFamily(binFamily: BinFamily){
      setBinFamilies(binFamilies=>([...binFamilies, binFamily]));
      setMessage('');
    }

    function removeBinFamily(binFamilyId: number) {
      setBinFamilies(binFamilies=>binFamilies.filter(v=>v.id !== binFamilyId));
    }

    function updateBinFamily(updatedBinFamily: BinFamily, binFamilyId: number) {
      setBinFamilies(binFamilies=>{
        const index = binFamilies.findIndex(v=>v.id === binFamilyId);
        const updatedList = [...binFamilies];
        updatedList[index] = updatedBinFamily;
        return updatedList;
      });
    }

    binFamiliesService.on('bin-family-created', addBinFamily);
    binFamiliesService.on('bin-family-deleted', removeBinFamily);
    binFamiliesService.on('bin-family-updated', updateBinFamily);

    return ()=>{
      binFamiliesService.off('bin-family-created', addBinFamily);
      binFamiliesService.off('bin-family-deleted', removeBinFamily);
      binFamiliesService.off('bin-family-updated', updateBinFamily);
    }

  }, []);

  return [binFamilies, loading, message];
}
