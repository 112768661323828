
import React, { useState } from 'react';
import { peopleService } from '../../services/api';
import { PersonRowProps } from './types';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import Grid from '@material-ui/core/Grid';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import PersonEditDialog from '../../dialogs/PersonEditDialog';
import ActiveIcon from '@material-ui/icons/Check';
import InActiveIcon from '@material-ui/icons/HourglassEmpty';

const PersonRow: React.FC<PersonRowProps> = function(props) {
  const { person } = props;
  const [openPersonEditDialog, setOpenPersonEditDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const confirm = useConfirmDialog();
  const notify = useNotifier();

  async function handleDelete() {
    try{
      if(await confirm('Are you sure?', 'Deleting person will completely remove them from the system. This action cannot be undone.')){
        setLoading(true);
        await peopleService.deleteById(person.id);
      }
    }
    catch(err){
      setLoading(false);
      notify(err);
    }
  }

  return (
    <TableRow>
      <TableCell>{ person.status === 'ACTIVE' ? <ActiveIcon color="primary" /> : <InActiveIcon /> }</TableCell>
      <TableCell>3hd.us/...{ person.person_id.slice(-5) }</TableCell>
      <TableCell>{ person.get('full_name') }</TableCell>
      <TableCell>{ person.email }</TableCell>
      <TableCell>
        <Grid container spacing={1} justify="flex-end">
          <Grid item>
            <IconButton
              size="small"
              onClick={handleDelete}
              disabled={loading}
            >
              <DeleteIcon color="error" />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              size="small"
              disabled={loading}
              onClick={()=>setOpenPersonEditDialog(true)}
            >
              <EditIcon color="action" />
            </IconButton>

            <PersonEditDialog
              open={openPersonEditDialog}
              onClose={()=>setOpenPersonEditDialog(false)}
              person={person}
            />
          </Grid>
        </Grid>
      </TableCell>
    </TableRow>
  );
};

export default PersonRow;
