
import React, { useRef, useState } from 'react';
import Vendor from '../../models/Vendor';
import { vendorsService } from '../../services/api';
import useSearchFilter from '../../hooks/use-search-filter';
import useVendors from '../../hooks/use-vendors';
import useGlobalConfig from '../../hooks/use-global-config';
import { useNotifier } from '../../providers/NotifierProvider';
import VendorDialog, { VendorDialogRef, VendorData } from '../../dialogs/VendorDialog';
import SearchBar from '../../components/SearchBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InfoMessage from '../../components/InfoMessage';
import VendorCard from './VendorCard';
import AddIcon from '@material-ui/icons/Add';

const VendorsPage: React.FC = function() {
  const [vendors, loading, message] = useVendors();
  const [searchTerm, setSearchTerm] = useState('');
  const searchedVendors = useSearchFilter(vendors, 'name,erp_number', searchTerm);
  const vendorDialog = useRef<VendorDialogRef>(null);
  const notify = useNotifier();

  useGlobalConfig({ title: 'Vendors', loading });

  async function handleVendorCreate(data: VendorData) {
    await vendorsService.create(data);
    notify('Vendor created.');
  }

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={2} pb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs md={4}>
              <SearchBar
                value={searchTerm}
                onChange={(ev)=>setSearchTerm(ev.target.value)}
                onClear={()=>setSearchTerm('')}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Fab
                size="medium"
                color="primary"
                disabled={loading}
                onClick={()=>vendorDialog.current?.openDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>

        

        {!loading && !!message && (
          <Box py={1}>
            <InfoMessage>{message}</InfoMessage>
          </Box>
        )}
      </Container>
      
      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container spacing={1}>
            {searchedVendors.map((vendor: Vendor)=>(
              <Grid key={''+vendor.id} item xs={12} sm={4} md={3} xl={2}>
                <VendorCard vendor={vendor} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <VendorDialog
          ref={vendorDialog}
          primaryActionText="Create"
          secondaryActionText="Cancel"
          primaryAction={handleVendorCreate}
        />
      </Container>
    </>
  );
}

export default VendorsPage;
