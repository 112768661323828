
import React, { forwardRef, useState, useImperativeHandle } from 'react';
import { PdfLinkDialogProps, PdfLinkDialogRef } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import styles from './styles';

const useStyles = makeStyles(styles);

const PdfLinkDialog: React.ForwardRefRenderFunction<PdfLinkDialogRef, PdfLinkDialogProps> = function(props, ref) {
  const {
    title = 'Download pdf',
    pdfUri
  } = props;

  const classes = useStyles();
  const [open, setOpen] = useState(false);

  useImperativeHandle(ref, ()=>{
    function openDialog() {
      setOpen(true);
    }

    return { openDialog };
  }, []);

  return (
    <Dialog
      open={open}
      onClose={()=>setOpen(false)}
      fullWidth
      maxWidth="xs"
    >
      <DialogTitle>{ title }</DialogTitle>
      <DialogContent>
        <Box pt={4} pb={2}>
          <Typography className={classes.iconWrapper} >
            <CheckIcon fontSize="inherit" color="inherit" />
          </Typography>

          <Typography align="center" variant="body1" gutterBottom>
            Pdf has been generated!
          </Typography>

          <Typography align="center">
            <Link href={pdfUri} target="_BLANK" underline="none">
              <Button
                variant="outlined"
                color="primary"
                size="large"
                startIcon={<OpenInBrowserIcon />}
              >
                Open document
              </Button>
            </Link>
          </Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default forwardRef(PdfLinkDialog);
