
import React, { useState } from 'react';
import { FiltersMap } from './types';
import useShoppingLists from '../../hooks/use-shopping-lists';
import useGlobalConfig from '../../hooks/use-global-config';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DataGridView from './DataGridView'
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import InfoMessage from '../../components/InfoMessage';
import FiltersBar from './FiltersBar';

const ShoppingListHistoryPage: React.FC = function() {
  const [filters, setFilters] = useState<FiltersMap>({});
  const [page, setPage] = useState(1);
  const [shoppingLists, loading, message, paginationInfo] = useShoppingLists(page, 'deleted', filters);
  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });
  const [displayType] = useState(isSmallScreen ? 'data-grid' : 'data-grid');

  useGlobalConfig({ title: 'Shopping list history', loading, showBackButton: true });

  return (
    <>
      <Container maxWidth={false}>
        <Box py={2}>
          <FiltersBar
            value={filters}
            onChange={filters=>setFilters(filters)}
            disabled={loading}
          />
          {!loading && !!message && (
            <Box py={2}>
              <InfoMessage>{ message }</InfoMessage>
            </Box>
          )}
        </Box>
      </Container>
      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container>
            {displayType === 'data-grid' && shoppingLists.length > 0 && (
              <Grid item xs={12}>
                <Paper>
                  <DataGridView
                    rows={shoppingLists}
                    containerHeight={700}
                    DataGridProps={{
                      columns: [], // Unnecessary but required. TODO: find a fix
                      rows: [], // Unnecessary but required. TODO: find a fix
                      paginationMode: "server",
                      rowCount: paginationInfo.total_count,
                      pageSize: paginationInfo.count,
                      rowsPerPageOptions: [paginationInfo.count],
                      loading: loading,
                      onPageChange: params=>setPage(params.page)
                    }}
                  />
                </Paper>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default ShoppingListHistoryPage;
