
import React, { useEffect, useState } from 'react';
import useAuthentication from '../../hooks/use-authentication';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import ClearIcon from '@material-ui/icons/Clear';

const HeyTherePage: React.FC = function() {
  const authenticationDetails = useAuthentication();
  const [shouldntTakeLong, setShouldntTakeLong] = useState(false);

  const isInsideIframe = (()=>{
    try{
      return window.self !== window.parent;
    }catch (e) {
      return true;
    }
  })();

  useEffect(()=>{
    // TODO: Change '*' to 'https://www.3hd.us'
    window.parent.postMessage({type: 'CURRENT_PERSON', data: authenticationDetails?.person}, '*');
    window.parent.postMessage({type: 'AUTH_DETAILS', data: authenticationDetails}, '*');
  }, [authenticationDetails]);

  useEffect(()=>{
    const timerId = setTimeout(()=>setShouldntTakeLong(true), 3000);
    return ()=>{ clearTimeout(timerId); }
  }, []);

  return (
    <Container maxWidth="xs">
      <Box pt={10} pb={2} textAlign="center">
        <CircularProgress />

        {shouldntTakeLong && (
          <Box mt={5} textAlign="center">
            <Typography variant="h4" gutterBottom>You shouldn't be here for this long</Typography>
            <Typography gutterBottom>You shouldn't be seeing this screen for this long. There is nothing in process here. You can close this screen now.</Typography>

            {isInsideIframe && (
              <IconButton onClick={()=>window.parent.postMessage({type: 'CLOSE', data: 'Unknown origin.'}, '*')} size="medium">
                <ClearIcon color="error" />
              </IconButton>
            )}

          </Box>
        )}
      </Box>
    </Container>
  );
};

export default HeyTherePage;
