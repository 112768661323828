
import React, { forwardRef, useImperativeHandle } from 'react';
import { useFormik } from 'formik';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const defaultValues = {
  first_name: '',
  last_name: '',
  phone: '',
  email: ''
}

const PersonForm: React.RefForwardingComponent<any, any> = function(props, ref) {
  const {
    initialValues,
    disabled
  } = props;

  const formik = useFormik({
    initialValues: Object.assign(defaultValues, {
      first_name: (initialValues || {}).first_name || '',
      last_name: (initialValues || {}).last_name || '',
      phone: (initialValues || {}).phone || '',
      email: (initialValues || {}).email || ''
    }),
    onSubmit: values=>{
      if(props.onSubmit)
        props.onSubmit(values);
    }
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return <form onSubmit={formik.handleSubmit}>
    <Grid container direction="column" spacing={2}>
      <Grid item>
        <TextField
          label="First name"
          disabled={disabled}
          {...formik.getFieldProps('first_name')}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Last name"
          disabled={disabled}
          {...formik.getFieldProps('last_name')}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Email"
          disabled={disabled}
          {...formik.getFieldProps('email')}
        />
      </Grid>
      <Grid item>
        <TextField
          label="Phone"
          disabled={disabled}
          {...formik.getFieldProps('phone')}
        />
      </Grid>
    </Grid>
  </form>;
}

export default forwardRef(PersonForm);
