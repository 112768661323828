
import { createStyles, Theme } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  thumbnailButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    padding: `${theme.spacing(2)}px`,
    cursor: 'pointer'
  },
  thumbnailLabel: {
    height: '35%'
  },
  iconWrapper: {
    fontSize: 60
  }
});
