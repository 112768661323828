import randomize from "randomatic";
import template from "./pdf-templates/order-plate";
import BinFamily from "../../../models/BinFamily";
import Bin from "../../../models/Bin";
import {momentTrackService} from "../../../services/moment-track";
import {binsService} from "../../../services/api";
import apiClient from "../../../services/api-client";

class OrderPlate {
  binFamilies: BinFamily[];

  constructor(data: BinFamily[]) {
    this.binFamilies = data;
  }

  async getPdf() {
    const allBins = await binsService.fetchAll();
    const binsMap = allBins.reduce((map, bin, i) => {
      map[bin.bin_family_id] = map[bin.bin_family_id] || [];
      map[bin.bin_family_id].push(bin);
      return map;
    }, {} as {[key: number]: Bin[]});

    let labelsData: any = [];

    this.binFamilies.forEach(binFamily => {
      const bins = binsMap[binFamily.id];
      if (!bins) return;

      bins.forEach((bin, i) => {
        if (i === 1) return; // Skip the second bin

        labelsData.push({
          url: momentTrackService.generateUrl(bin.id, "document"),
          title: binFamily.product.name,
          subtitle: binFamily.product.part_number,
        });
      });
    });

    const requestData = {
      html: template(labelsData, 21),
      pdf_mode: "portrait",
      pdf_name: `order-plate-${randomize("a0", 6)}`,
      page_size: "Letter",
      margin_top: "0mm",
      margin_bottom: "0mm",
      margin_left: "0mm",
      margin_right: "0mm",
      disable_smart_shrinking: null,
    };

    const response = await apiClient.post(
      process.env.REACT_APP_HTML_2_PDF_URL || "",
      requestData
    );
    // await momentTrackService.printPdf(response.data.data?.s3_path, 'Bin family labels from ordertrack');
    return response.data?.s3_path;
  }
}

export default OrderPlate;
