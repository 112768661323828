
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { placesService } from '../../services/places';
import Place from '../../models/Place';

export default function usePlaces(): [Place[], boolean, Error|string] {
  const [places, setPlaces] = useState<Place[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchPlaces = useCallback(async ()=>{
    try{
      setLoading(true);
      const places = await placesService.fetchAll();
      places.sort((a,b)=>a.name.localeCompare(b.name));
      setMessage(places.length === 0 ? 'Nothing found.' : '');
      setPlaces(places);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load places.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchPlaces();

  }, [ fetchPlaces ]);

  useEffect(()=>{
    function addPlace(place: Place){
      setPlaces(places=>([...places, place]));
      setMessage('');
    }

    function removePlace(placeId: number) {
      setPlaces(places=>places.filter(v=>v.id !== placeId));
    }

    function updatePlace(updatedPlace: Place, placeId: number) {
      setPlaces(places=>{
        const index = places.findIndex(l=>l.id === placeId);
        const updatedList = [...places];
        updatedList[index] = updatedPlace;
        return updatedList;
      });
    }

    placesService.on('place-created', addPlace);
    placesService.on('place-deleted', removePlace);
    placesService.on('place-updated', updatePlace);

    return ()=>{
      placesService.off('place-created', addPlace);
      placesService.off('place-deleted', removePlace);
      placesService.off('place-updated', updatePlace);
    }

  }, []);

  return [places, loading, message];
}
