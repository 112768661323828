
import React, { ChangeEvent } from 'react';
import { FiltersBarProps } from './types';
import usePlaces from '../../hooks/use-places';
import useVendors from '../../hooks/use-vendors';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const FiltersBar: React.FC<FiltersBarProps> = function(props) {
  const {
    onChange = ()=>{},
    value = {},
    disabled

  } = props;

  const [places, loadingPlaces] = usePlaces();
  const [vendors, loadingVendors] = useVendors();

  function changeHandler(key: string) {
    return (ev: ChangeEvent<HTMLInputElement>)=>{
      switch(key){
        case 'place':
          onChange({...value, location_id: ev.target.value || undefined});
          break;
        case 'vendor':
          onChange({...value, vendor_id: ev.target.value || undefined});
          break;
        default:
          break;
      }
    };
  }

  return (
    <Toolbar disableGutters>
      <Grid container spacing={2} alignItems="center">
        <Grid item >
          <Typography variant="caption" color="textSecondary">Filters</Typography>
        </Grid>
        <Grid item md={3}>
          <TextField
            value={value.location_id || ''}
            onChange={changeHandler('place')}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            label="Place"
            select
            SelectProps={{ native: true }}
            disabled={loadingPlaces || disabled}
          >
            <option value="">All places</option>
            {places.map(loc=>(
              <option key={''+loc.id} value={loc.id}>{loc.name}</option>
            ))}
          </TextField>
        </Grid>
        <Grid item md={3}>
          <TextField
            value={value.vendor_id || ''}
            onChange={changeHandler('vendor')}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            size="small"
            label="Vendor"
            select
            SelectProps={{ native: true }}
            disabled={loadingVendors || disabled}
          >
            <option value="">All vendors</option>
            {vendors.map(vendor=>(
              <option key={''+vendor.id} value={vendor.id}>{vendor.name}</option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    </Toolbar>
  );
};

export default FiltersBar;
