
import React, { useRef, useState } from 'react';
import { placesService } from '../../services/places';
import usePlaces from '../../hooks/use-places';
import useSearchFilter from '../../hooks/use-search-filter';
import { useNotifier } from '../../providers/NotifierProvider';
import useGlobalConfig from '../../hooks/use-global-config';
import PlaceDialog, { PlaceDialogRef, PlaceData } from '../../dialogs/PlaceDialog';
import PdfLinkDialog, { PdfLinkDialogRef } from '../../dialogs/PdfLinkDialog';
import SearchBar from '../../components/SearchBar';
import CardsView from './CardsView';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InfoMessage from '../../components/InfoMessage';
import AddIcon from '@material-ui/icons/Add';

const PlacesPage: React.FC = function() {
  const [places, loading, message] = usePlaces();
  const [searchTerm, setSearchTerm] = useState('');
  const [lastPlaceLabelUri, setLastPlaceLabelUri] = useState('');
  const searchedPlaces = useSearchFilter(places, 'name', searchTerm);
  const placeDialog = useRef<PlaceDialogRef>(null);
  const pdfLinkDialog = useRef<PdfLinkDialogRef>(null);
  const notify = useNotifier();

  useGlobalConfig({ title: 'Places', loading });

  async function handlePlaceCreate(data: PlaceData) {
    const place = await placesService.create(data);
    notify('Generating label...');
    // TODO: Prompt for template type to use.
    const labelPdf = await placesService.generateLabels([place], 'sheet');

    setLastPlaceLabelUri(labelPdf);
    pdfLinkDialog.current?.openDialog();

    notify('Place created.');
  }

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={2} pb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs md={4}>
              <SearchBar
                value={searchTerm}
                onChange={(ev)=>setSearchTerm(ev.target.value)}
                onClear={()=>setSearchTerm('')}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Fab
                size="medium"
                color="primary"
                disabled={loading}
                onClick={()=>placeDialog.current?.openDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>
        {!loading && !!message && (
          <Box py={1}>
            <InfoMessage>{message}</InfoMessage>
          </Box>
        )}
      </Container>

      <Container maxWidth={false}>
        <Box py={2}>
          <CardsView
            places={searchedPlaces}
            disabled={loading}
          />
        </Box>

        <PlaceDialog
          ref={placeDialog}
          primaryActionText="Create"
          secondaryActionText="Cancel"
          primaryAction={handlePlaceCreate}
        />

        <PdfLinkDialog
          ref={pdfLinkDialog}
          title="Place label"
          pdfUri={lastPlaceLabelUri}
        />
      </Container>
    </>
  );
}

export default PlacesPage;
