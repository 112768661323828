
import React, { useRef, useState } from 'react';
import { productsService } from '../../services/api';
import useGlobalConfig from '../../hooks/use-global-config';
import useSearchFilter from '../../hooks/use-search-filter';
import { useNotifier } from '../../providers/NotifierProvider';
import useProducts from '../../hooks/use-products';
import SearchBar from '../../components/SearchBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import InfoMessage from '../../components/InfoMessage';
import ProductDialog, { ProductDialogRef, ProductData } from '../../dialogs/ProductDialog';
import ProductCard from './ProductCard';

const ProductsPage: React.FC = function() {
  const [products, loading, message] = useProducts();
  const [searchTerm, setSearchTerm] = useState('');
  const searchedProducts = useSearchFilter(products, 'name,description,part_number,preferred_vendor_part_number', searchTerm);
  const productDialog = useRef<ProductDialogRef>(null);
  const notify = useNotifier();
  useGlobalConfig({ title: 'Products', loading });

  async function handleProductCreate(data: ProductData) {
    await productsService.create(data);
    notify('Product created.');
  }

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={2} pb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs md={4}>
              <SearchBar
                value={searchTerm}
                onChange={(ev)=>setSearchTerm(ev.target.value)}
                onClear={()=>setSearchTerm('')}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Fab
                size="medium"
                color="primary"
                disabled={loading}
                onClick={()=>productDialog.current?.openDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>
        {!loading && !!message && (
          <Box py={1}>
            <InfoMessage>{message}</InfoMessage>
          </Box>
        )}
      </Container>
      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container spacing={1}>
            {searchedProducts.map(product=>(
              <Grid key={''+product.id} item xs={12} sm={4} md={3}>
                <ProductCard
                  product={product}
                />
              </Grid>
            ))}
          </Grid>
        </Box>

        <ProductDialog
          ref={productDialog}
          primaryActionText="Create"
          secondaryActionText="Cancel"
          primaryAction={handleProductCreate}
        />
      </Container>
    </>
  );
};

export default ProductsPage;
