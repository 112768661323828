
import { useEffect, useState } from 'react';
import { authenticationStore } from '../../services/store';
import Person from '../../models/Person';

export default function useCurrentPerson() {
  const [currentPerson, setCurrentPerson] = useState<Person | null>(authenticationStore.get('person') as Person);

  useEffect(()=>{
    authenticationStore.on('person', setCurrentPerson);
    
    return ()=>{
      authenticationStore.off('person', setCurrentPerson);
    }
  }, []);

  return currentPerson;
}