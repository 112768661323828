
import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  buttonThumbnail: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    cursor: 'pointer'
  },
  mainIcon: {
    maxWidth: 88,
    maxHeight: 88,
    marginBottom: theme.spacing(1)
  },
  clearButton: {
    position: 'absolute',
    top: 4,
    right: 4
  }
});
