
import React, { useRef, useState } from 'react';
import { momentTrackService } from '../../services/moment-track';
import { binFamiliesService } from '../../services/bin-families';
import { binsService } from '../../services/api';
import { RouteComponentProps } from 'react-router-dom';
import useGlobalConfig from '../../hooks/use-global-config';
import useBinFamily from '../../hooks/use-bin-family';
import useBins from '../../hooks/use-bins';
import { useNotifier } from '../../providers/NotifierProvider';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import BinDialog, { BinDialogRef, BinData } from '../../dialogs/BinDialog';
import PdfLinkDialog, { PdfLinkDialogRef } from '../../dialogs/PdfLinkDialog';
import BinCard from './BinCard';
import PlaceScanner from '../../components/QrCodeScanner';
import MenuButton from '../../components/MenuButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import InfoMessage from '../../components/InfoMessage';
import AddIcon from '@material-ui/icons/Add';
import PdfIcon from '@material-ui/icons/PictureAsPdf';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import PinDropIcon from '@material-ui/icons/PinDrop';

const BinFamilyPage: React.FC<RouteComponentProps<{binFamilyId: string}>> = function(props) {
  const { match } = props;

  const binDialog = useRef<BinDialogRef>(null);
  const pdfLinkDialog = useRef<PdfLinkDialogRef>(null);
  const notify = useNotifier();
  const confirm = useConfirmDialog();
  const binFamilyId = Number(match.params.binFamilyId);
  const [binFamily, loadingBinFamily, message] = useBinFamily(binFamilyId);
  const [bins, loadingBins, binsMessage] = useBins(binFamilyId);
  const [labelsDocumentUrl, setLabelsDocumentUrl] = useState('');
  const [generatingLabels, setGeneratingLabels] = useState(false);
  const [movingBin, setMovingBin] = useState(false);

  async function handleBinCreate(data: BinData) {
    await binsService.create(data);
    notify('New bin added to family.');
  }

  async function handleGenerateLabels(templateType: 'sheet' | 'single') {
    try{
      if(!binFamily || !(await confirm('Continue?', 'Generating pdf document for labels')))
        return;
        
      setGeneratingLabels(true);
      const labelsPdf = await binFamiliesService.generateLabels([binFamily], templateType);
      setLabelsDocumentUrl(labelsPdf);
      pdfLinkDialog.current?.openDialog();
      notify('Labels generated.');
    }
    catch(err){
      notify(err);
    }
    finally{
      setGeneratingLabels(false);
    }
  }

  async function handlePlaceScan(placeUrl: string) {
    try{
      const idInfo = momentTrackService.parseId(placeUrl);

      if(idInfo.type !== 'place' || !Number(idInfo.id))
        throw new Error('Invalid place id.');

      if(!(await confirm('Are you sure?', 'Move this bin family to this place.')))
        return notify('Cancelled.');

      setMovingBin(true);
      const updatedFamily = await binFamiliesService.moveFamily(binFamilyId, Number(idInfo.id));
      notify(`Bin family moved to new place "${updatedFamily.location.name}".`);
    }
    catch(err){
      notify(err);
    }
    finally{
      setMovingBin(false);
    }
  }

  const loading = loadingBinFamily || loadingBins || generatingLabels || movingBin;
  const canAddBins = !loading && !!binFamily && bins.length < 2;
  const canPrintLabels = bins.length === 2;

  useGlobalConfig({ title: 'Bin family', loading, showBackButton: true });

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Grid container spacing={2} justify="space-between" alignItems="center">
              <Grid item>
                <Typography variant="h6">{loading ? '...' : (binFamily ? binFamily.product.name : message)}</Typography>
                <Typography variant="body2" color="textSecondary">Bin family</Typography>
              </Grid>
            </Grid>
          </Box>

          {!loading && !!binsMessage && (
            <Box p={2}>
              <InfoMessage>{ binsMessage }</InfoMessage>
            </Box>
          )}
        </Paper>

        <Box py={2}>
          <Grid container spacing={1}>
            {bins.map(bin=>(
              <Grid key={''+bin.id} item xs={12} sm={4} md={3} xl={2}>
                <BinCard bin={bin} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <Box py={2}>
          {canAddBins && (
            <Button
              color="primary"
              disabled={loading}
              startIcon={<AddIcon />}
              size="large"
              onClick={()=>binDialog.current?.openDialog(true)}
            >
              Add bin
            </Button>
          )}
          {canPrintLabels && (
            <>
              <Button
                color="primary"
                size="large"
                startIcon={<PdfIcon />}
                disabled={loading}
                onClick={()=>handleGenerateLabels('sheet')}
              >
                Generate labels
              </Button>

              {false && <MenuButton
                color="primary"
                size="large"
                startIcon={<PdfIcon />}
                disabled={loading}
                onClick={()=>handleGenerateLabels('sheet')}
                menuItems={[
                  {
                    label: 'Letter, 30 labels sheet',
                    onClick: ()=>handleGenerateLabels('sheet')
                  },
                  {
                    label: 'Single label (2 5/8" x 1" roll)',
                    onClick: ()=>handleGenerateLabels('single')
                  }
                ]}
              >
                Generate labels
              </MenuButton>}
            </>
          )}
        </Box>

        {!!labelsDocumentUrl && (
          <Box pb={2}>
            <Button
              color="primary"
              disabled={loading || generatingLabels}
              startIcon={<OpenInNewIcon />}
              size="large"
              onClick={()=>window.open(labelsDocumentUrl, '_blank')}
            >
              Open label document
            </Button>
          </Box>
        )}

        {false && <Box pb={2}>
          <PlaceScanner
            title="Scan place QR"
            buttonText="Move to new place"
            onResult={handlePlaceScan}
            ButtonProps={{
              color: 'primary',
              disabled: loading,
              startIcon: <PinDropIcon />,
              size: 'large'
            }}
          />
        </Box>}
      </Box>

      <BinDialog
        ref={binDialog}
        bin={{bin_family_id: binFamilyId}}
        primaryActionText="Create"
        secondaryActionText="Cancel"
        primaryAction={handleBinCreate}
      />

      <PdfLinkDialog
        ref={pdfLinkDialog}
        title="Bin family labels"
        pdfUri={labelsDocumentUrl}
      />
    </Container>
  );
};

export default BinFamilyPage;
