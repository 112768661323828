
import { useState, useEffect, useCallback } from 'react';
import { placesService } from '../../services/places';
import Place from '../../models/Place';
import { useNotifier } from '../../providers/NotifierProvider';

export default function usePlace(placeId: number): [Place|null, boolean, string] {
  const [place, setPlace] = useState<Place | null>(null);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const notify = useNotifier();

  const fetchPlace = useCallback(async ()=>{
    try{
      setLoading(true);
      const place = await placesService.fetchById(placeId);
      setPlace(place);
      setMessage('');
    }
    catch(err){
      setMessage('Failed to fetch place.');
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }, [placeId, notify]);

  useEffect(()=>{
    fetchPlace();
  }, [fetchPlace]);

  return [place, loading, message];
}
