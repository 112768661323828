
import React, { useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { PlaceDialogProps, PlaceDialogRef } from './types';
import { useNotifier } from '../../providers/NotifierProvider';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import PlaceForm, { PlaceFormRef, PlaceData } from '../../forms/PlaceForm';

const PlaceDialog: React.ForwardRefRenderFunction<PlaceDialogRef, PlaceDialogProps> = function(props, ref) {
  const {
    place,
    title = 'Place',
    primaryActionText = 'Done',
    secondaryActionText = 'Close',
    primaryAction = ()=>{},
    secondaryAction = ()=>{}
  } = props;

  const placeForm = useRef<PlaceFormRef>(null);
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editable, setEditable] = useState(false);

  async function handlePrimaryAction(data: PlaceData) {
    try{
      setLoading(true);
      await primaryAction(data);
      setOpen(false);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  async function handleSecondaryAction() {
    try{
      setLoading(true);
      await secondaryAction();
      setOpen(false);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  useImperativeHandle(ref, ()=>{
    function openDialog(editable = false) {
      setEditable(editable);
      setOpen(true);
    }

    return {openDialog};
  }, []);

  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      disableBackdropClick={loading}
      disableEscapeKeyDown={loading}
      onClose={()=>setOpen(false)}
    >
      <DialogTitle>{title}</DialogTitle>
      {!editable && <Divider />}
      <DialogContent>
        <Box pt={2} pb={4}>
          {editable && (
            <PlaceForm
              ref={placeForm}
              initialValues={place}
              onSubmit={handlePrimaryAction}
              disabled={loading}
            />
          )}

          {!editable && !!place && (
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">NAME</Typography>
                <Typography variant="body1">{place.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="caption" color="textSecondary">PARENT PLACE</Typography>
                <Typography variant="body1">{place.parent_location_id}</Typography>
              </Grid>
            </Grid>
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        {loading && (
          <CircularProgress size={24} color="primary" />
        )}

        <Button
          disabled={loading}
          onClick={handleSecondaryAction}
        >
          {secondaryActionText}
        </Button>

        {editable && (
          <Button
            disabled={loading}
            variant="contained"
            color="primary"
            onClick={()=>placeForm.current?.submitForm()}
          >
            {primaryActionText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default forwardRef(PlaceDialog);
