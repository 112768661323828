
import React, { useRef, useState } from 'react';
import { printersService } from '../../services/api';
import usePrinters from '../../hooks/use-printers';
import { useNotifier } from '../../providers/NotifierProvider';
import useSearchFilter from '../../hooks/use-search-filter';
import useGlobalConfig from '../../hooks/use-global-config';
import PrinterDialog, { PrinterDialogRef, PrinterData } from '../../dialogs/PrinterDialog';
import SearchBar from '../../components/SearchBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InfoMessage from '../../components/InfoMessage';
import PrinterCard from './PrinterCard';
import AddIcon from '@material-ui/icons/Add';

const PrintersPage: React.FC = function() {
  const [printers, loading, message] = usePrinters();
  const [searchTerm, setSearchTerm] = useState('');
  const searchedPrinters = useSearchFilter(printers, 'name,url', searchTerm);
  const printerDialog = useRef<PrinterDialogRef>(null);
  const notify = useNotifier();

  useGlobalConfig({ title: 'Printers', loading });

  async function handlePrinterCreate(data: PrinterData) {
    await printersService.create(data);
    notify('Printer created.');
  }

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={2} pb={1}>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs md={4}>
              <SearchBar
                value={searchTerm}
                onChange={(ev)=>setSearchTerm(ev.target.value)}
                onClear={()=>setSearchTerm('')}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Fab
                size="medium"
                color="primary"
                disabled={loading}
                onClick={()=>printerDialog.current?.openDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>
        {!loading && !!message && (
          <Box py={1}>
            <InfoMessage>{message}</InfoMessage>
          </Box>
        )}
      </Container>

      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container spacing={1}>
            {searchedPrinters.map(printer=>(
              <Grid key={''+printer.id} item xs={12} sm={4} md={3} xl={2}>
                <PrinterCard printer={printer} />
              </Grid>
            ))}
          </Grid>
        </Box>

        <PrinterDialog
          ref={printerDialog}
          primaryActionText="Create"
          secondaryActionText="Cancel"
          primaryAction={handlePrinterCreate}
        />
      </Container>
    </>
  );
}

export default PrintersPage;
