
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useGlobalConfig from '../../hooks/use-global-config';
import { Link as RouterLink } from 'react-router-dom';
import Ratioedbox from '../../components/Box';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import CropFreeIcon from '@material-ui/icons/CropFree';
import styles from './styles';

const useStyles = makeStyles(styles);

const DashboardPage: React.FC = function() {
  const classes = useStyles();

  useGlobalConfig({ title: 'Dashboard' });

  return (
    <Container maxWidth={false}>
      <Box py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4} lg={3}>
            <Link to="/i-am-empty" underline="none" component={RouterLink}>
              <Paper elevation={1}>
                <Ratioedbox sizeRatio="1:1" className={classes.thumbnailButton}>
                  <Typography className={classes.iconWrapper}>
                    <CropFreeIcon fontSize="inherit" />
                  </Typography>
                  <Typography variant="h6" className={classes.thumbnailLabel}>Scan empty to auto add</Typography>
                </Ratioedbox>
              </Paper>
            </Link>
          </Grid>

          <Grid item xs={12} sm={4} lg={3}>
            <Link to="/custom-order" underline="none" component={RouterLink}>
              <Paper elevation={1}>
                <Ratioedbox sizeRatio="1:1" className={classes.thumbnailButton}>
                  <Typography className={classes.iconWrapper}>
                    <CropFreeIcon fontSize="inherit" />
                  </Typography>
                  <Typography variant="h6" className={classes.thumbnailLabel}>Scan to order specific amount</Typography>
                </Ratioedbox>
              </Paper>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default DashboardPage;
