
import React from 'react';
import { SearchBarProps } from './types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import Divider from '@material-ui/core/Divider';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import styles from './styles';

const useStyles = makeStyles(styles);

const SearchBar: React.FC<SearchBarProps> = function(props) {
  const {
    value,
    disabled = false,
    onClear = ()=>{},
    ...inputBaseProps
  } = props;

  const classes = useStyles();

  return (
    <Paper className={classes.root} elevation={1}>
      <IconButton className={classes.iconButton}
        disabled={disabled}
      >
        <SearchIcon />
      </IconButton>
      <Divider orientation="vertical" className={classes.divider} />
      <InputBase
        className={classes.input}
        disabled={disabled}
        placeholder="Search here..."
        value={value}
        {...inputBaseProps}
      />
      {!!value && (
        <IconButton className={classes.iconButton}
          onClick={onClear}
          disabled={disabled}
        >
          <ClearIcon color="error" />
        </IconButton>
      )}
    </Paper>
  );
};

export default SearchBar;
