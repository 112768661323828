
import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { BoxProps } from '@material-ui/core/Box';
import useScrollPosition from './use-scroll-position';

const useStyles = makeStyles(()=>({
  contentContainer: {
    overflow: 'auto',
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column'
  },
  wrapper: {
    position: 'relative',
    flex: 'auto',
    display: 'flex',
    overflow: 'hidden',
    '&:before, &:after': {
      content: '""',
      display: 'block',
      height: 1,
      width: '100%',
      position: 'absolute',
      left: 0,
      zIndex: 99,
      transition: 'box-shadow 150ms'
    },
    '&:before': {
      top: 0,
      marginTop: -1
    },
    '&:after': {
      bottom: 0,
      marginBottom: -1
    }
  },
  scrollPosTop: {
    '&:after': {
      boxShadow: '0 0 12px 6px rgba(0,0,0,0.25)'
    }
  },
  scrollPosBottom: {
    '&:before': {
      boxShadow: '0 0 12px 6px rgba(0,0,0,0.25)'
    }
  },
  scrollPosMiddle: {
    '&:before': {
      boxShadow: '0 0 12px 6px rgba(0,0,0,0.25)'
    },
    '&:after': {
      boxShadow: '0 0 12px 6px rgba(0,0,0,0.25)'
    }
  }
}));

const Content: React.FC<BoxProps> = ({className, ...props}) => {
  const classes = useStyles();
  const [scrollPos, containerRef] = useScrollPosition();

  return (
    <div
      className={clsx(classes.wrapper, {
        [classes.scrollPosTop]: scrollPos === 'top',
        [classes.scrollPosMiddle]: scrollPos === 'middle',
        [classes.scrollPosBottom]: scrollPos === 'bottom'
      })}
    >
      <main
        ref={containerRef}
        className={clsx(classes.contentContainer, className)}
        {...props}
      />
    </div>
  );
};

export default Content;
