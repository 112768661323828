
import React, { useState, useRef } from 'react';
import moment from 'moment';
import { momentTrackService } from '../../services/moment-track';
import { shoppingListsService } from '../../services/shopping-lists';
import ShoppingList from '../../models/ShoppingList';
import { ShoppingListDataGridProps } from './types';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import DataGrid, { DataGridRef, ColumnDef, RowData } from '../../components/DataGrid';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Toolbar from '@material-ui/core/Toolbar';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import DownloadIcon from '@material-ui/icons/GetApp';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const columnDefinitions: ColumnDef[] = [
  {
    field: 'productName',
    headerName: 'PRODUCT',
    valueGetter: data=>data.product.name,
    sortable: true
    // width: 250
  },
  {
    field: 'quantity',
    headerName: 'QTY',
    sortable: true
  },
  {
    field: 'vendorName',
    headerName: 'VENDOR',
    valueGetter: data=>data.vendor.name,
    sortable: true
    // width: 150
  },
  {
    field: 'created_at',
    headerName: 'CREATED AT',
    valueGetter: data=>moment.utc(data.created_at).local().format('MMM DD, YYYY @ hh:mma'),
    sortable: true
    // width: 200
  },
  {
    field: 'actions',
    headerName: 'MARK DONE',
    width: 200,
    align: 'center',
    padding: 'checkbox',
    renderCell: data=>{
      function DoneButton() {
        const notify = useNotifier();
        const [working, setWorking] = useState(false);
        const [confirmMode, setConfirmMode] = useState(false);
        const shoppingListId = data.id;

        async function handleDelete(ev: any) {
          try{
            ev.stopPropagation();
            ev.preventDefault();

            setConfirmMode(false);
            setWorking(true);
            await shoppingListsService.deleteById(shoppingListId);
            notify('Item removed.');
          }
          catch(err){
            notify(err);
            setWorking(false);
          }
        }

        return (
          <Box width="100%" display="flex" alignItems="center" justifyContent="center">
            {!working && !confirmMode && (
              <Fab
                size="small"
                color="primary"
                onClick={()=>setConfirmMode(true)}
              >
                <CheckIcon />
              </Fab>
            )}
            {working && (
              <CircularProgress size={32} />
            )}
            {confirmMode && !working && (
              <>
                <Typography variant="caption" style={{ marginRight: 8 }}>Continue?</Typography>

                <IconButton
                  onClick={()=>setConfirmMode(false)}
                  size="small"
                >
                  <ClearIcon color="error" />
                </IconButton>

                <IconButton
                  onClick={handleDelete}
                  size="small"
                >
                  <CheckIcon color="primary" />
                </IconButton>
              </>
            )}
          </Box>
        );
      };

      return <DoneButton />
    }
  },
  {
    field: 'bin',
    headerName: 'BIN (product QR)',
    valueGetter: data=>momentTrackService.generateUrl(data.bin.id, 'product'),
    // width: 290
  },
  {
    field: 'placeName',
    headerName: 'PLACE',
    valueGetter: data=>data.location.name,
    sortable: true
    // width: 150
  },
  {
    field: 'placeUrl',
    headerName: 'PLACE (url)',
    valueGetter: data=>momentTrackService.generateUrl(data.location.id, 'place'),
    // width: 200
  }
];

const ShoppingListDataGrid: React.FC<ShoppingListDataGridProps> = function(props) {
  const {
    rows,
    onRemove,
    DataGridProps = {}
  } = props;

  const dataGrid = useRef<DataGridRef>(null);
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [selection, setSelection] = useState<RowData[]>([]);

  async function handleCsvExport() {
    try{
      if(selection.length === 0)
        throw new Error('No rows has been selected for export.');

      if(await confirm('Continue?', `Exporting ${selection.length} rows to CSV.`)){
        shoppingListsService.downloadCsv(selection as ShoppingList[]);
      }
    }
    catch(err){
      notify(err);
    }
  }

  return (
    <Paper>
      <Toolbar>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          <Grid item>
            <Typography variant="h6">Active orders</Typography>
          </Grid>

          <Grid item xs zeroMinWidth />

          {/* <Grid item>
            <Button
              disabled={rows.length === 0}
              onClick={()=>{
                dataGrid.current?.setSelection(rows.filter(row=>row.vendor.name.toLowerCase() === 'amazon'));
              }}
            >
              Add to amazon cart
            </Button>
          </Grid>

          <Grid item>
            <Button
              disabled={rows.length === 0}
              onClick={()=>{
                dataGrid.current?.setSelection(rows.filter(row=>row.vendor.name.toLowerCase() === 'amazon'));
              }}
            >
              Select Amazon products
            </Button>
          </Grid> */}

          <Grid item>
            <IconButton
              onClick={()=>onRemove(selection as ShoppingList[])}
              disabled={selection.length === 0}
            >
              <DeleteIcon color={selection.length === 0 ? 'inherit' : 'error'} />
            </IconButton>
          </Grid>

          <Grid item>
            <IconButton
              onClick={handleCsvExport}
              color="primary"
              disabled={selection.length === 0}
            >
              <DownloadIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
      <DataGrid
        ref={dataGrid}
        {...DataGridProps}
        disableSelectionOnClick
        checkboxSelection
        onSelectionChange={newSelection=>setSelection(newSelection)}
        rows={rows}
        columns={columnDefinitions}
      />
    </Paper>
  );
};

export default ShoppingListDataGrid;
