
import { createMuiTheme } from '@material-ui/core/styles';
import orange from '@material-ui/core/colors/orange';
import blueGrey from '@material-ui/core/colors/blueGrey';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#007dc8'
    },
    secondary: {
      main: orange['400'],
      light: orange['50']
    },
    background: {
      default: blueGrey['50']
    }
  },
  typography: {
    // h3: { fontWeight: 700 },
    // h4: { fontWeight: 700 },
    subtitle1: { fontWeight: 500 },
    subtitle2: { fontWeight: 400 }
  },
  props: {
    MuiPaper: {
      elevation: 0
    },
    MuiAppBar: {
      position: 'static',
      color: 'default',
      elevation: 1
    },
    MuiTextField: {
      variant: 'outlined',
      fullWidth: true
    }
  },
  overrides: {
    MuiButtonBase: {
      'root': {
        borderRadius: 0
      }
    },
    MuiButton: {
      'root': {
        borderRadius: 0
      }
    },
    MuiListItem: {
      'root': {
        '&$selected': {
          backgroundColor: blueGrey['50']
        }
      }
    },
    MuiLinearProgress: {
      'root': {
        marginBottom: -4
      }
    }
  }
});

export default mainTheme;
