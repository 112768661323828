
import api from '../api-client';
import EventEmitter from 'events';
import { authenticationStore } from '../store';
import Person from '../../models/Person';
import { ProfileInfo } from './types';

// TODO: Reload person info from server on application startup, in case info was updated from another device.

export default class AuthenticationService extends EventEmitter {
  isAuthenticated() {
    const token = authenticationStore.get('access_token');
    const person = authenticationStore.get('person');

    return !!token && !!person;
  }

  getAuthenticationDetails() {
    if(!this.isAuthenticated())
      return null;
      
    return {
      person: authenticationStore.get('person'),
      access_token: authenticationStore.get('access_token'),
      org_slug: authenticationStore.get('org_slug')
    };
  }

  async fetchCurrentPerson() {
    const response = await api.get(`/api/${authenticationStore.get('org_slug')}/users/me`);
    return new Person(response.data.data);
  }

  async login(email: string, password: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('password', password);
  
    const url = `/auth/${authenticationStore.get('org_slug')}/system/login`;
    const response = await api.post(url, formData);
    const data = response.data.data;
    
    authenticationStore.saveToken(data.access_token);
    authenticationStore.saveRefreshToken(data.refresh_token);
    authenticationStore.saveOrgSlug(data.org_slug);

    const person = await this.fetchCurrentPerson();
    authenticationStore.savePerson(person);
  
    this.emit('login', person);
    return 'You are logged in.';
  }

  logout() {
    authenticationStore.clearAll();
    this.emit('logout');
  }

  async updatePassword(password: string, org?: string): Promise<string> {
    const url = `/api/${org || authenticationStore.get('org_slug')}/users/update_password`;
    const response = await api.post(url, { password });
    return response.data.data;
  }

  async updateCurrentPerson(updateData: ProfileInfo) {
    const url = `/api/${authenticationStore.get('org_slug')}/users/me`;
    const response = await api.put(url, updateData);
  
    let updatedPerson = new Person(response.data.data);
    authenticationStore.savePerson(updatedPerson);

    return updatedPerson
  }

  async requestPasswordReset(email: string): Promise<string> {
    if(typeof email !== 'string' || !email.trim().length)
      throw new Error('Enter a valid email address.');

    const formData = new FormData();
    formData.append('email', email);
  
    const url = `/auth/${authenticationStore.get('org_slug')}/system/forgot`;
    const response = await api.post(url, formData);
    return response.data.data; // Success message.
  }

  async resetPassword(password: string, token: string, org?: string): Promise<string> {
    const url = `/auth/${org || authenticationStore.get('org_slug')}/system/reset/${token}`;
    const response = await api.post(url, { password });
    const data = response.data.data;

    authenticationStore.set('access_token', data.access_token);
    return this.updatePassword(password, org);
  }

  async sendInvite(email: string): Promise<string> {
    const url = `/api/${authenticationStore.get('org_slug')}/users/invite`;
    const response = await api.post(url, { email });
    return response.data.data; // Success message.
  }

  async acceptInvite(token: string, org?: string): Promise<string> {
    const url = `/auth/${org || authenticationStore.get('org_slug')}/system/invite-accept/${token}`;
    const response = await api.get(url/*, {
      params: { token }
    }*/)
  
    return response.data.data;
  }
}
