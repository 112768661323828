
import PlacesService from './PlacesService';
import Place from '../../models/Place';
import { authenticationStore } from '../store';
export { default } from './PlacesService';

export const placesService = new PlacesService(
  ()=>`/api/${authenticationStore.get('org_slug')}/locations`,
  'location',
  data=>new Place(data)
);
