
export default class CustomError extends Error {
  title: string;

  constructor(message = 'Something went wrong.', title = 'Unknown error') {
    super(message);
    // this.name = 'CustomError';
    // TODO: This is a temporary thing. Manage titling in notifier for better formatted errors.
    this.name = title;
    this.title = title;
  }
}
