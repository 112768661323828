
import React, { useMemo } from 'react';
import { BoxProps } from './types';
import MuiBox from '@material-ui/core/Box';

const Box: React.FC<BoxProps> = function(props) {
  const {
    sizeRatio,
    children,
    ...MuiBoxProps
  } = props;

  const size = useMemo(()=>{
    const ratioRegEx = /^(\d+):(\d+)$/;
    const defaultSize = { width: '100%', height: 'auto' };

    if(typeof sizeRatio !== 'string' || !ratioRegEx.test(sizeRatio))
      return defaultSize;

    const ratioParts = sizeRatio.match(ratioRegEx);

    if(!ratioParts)
      return defaultSize;

    return {
      width: '100%',
      height: (1 / Number(ratioParts[1]) * Number(ratioParts[2])) * 100 + '%'
    };

  }, [sizeRatio]);

  return (
    <MuiBox position="relative" width={size.width} pt={size.height}>
      <MuiBox position="absolute" top={0} left={0} width="100%" height="100%">
        <MuiBox height="100%" {...MuiBoxProps}>{ children }</MuiBox>
      </MuiBox>
    </MuiBox>
  );
};

export default Box;
