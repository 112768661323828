
import { createStyles, Theme } from '@material-ui/core/styles';

const styles = (theme: Theme) => createStyles({
  listItem: {
    '&:hover': {
      backgroundColor: theme.palette.grey['100']
    }
  }
});

export default styles;
