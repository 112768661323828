
import EventEmitter from 'events';
import { GlobalConfig } from './types';
import defaultConfig from './default-config';

export default class GlobalConfigService extends EventEmitter {
  private config = defaultConfig;

  getConfig() {
    return this.config;
  }

  update(data: GlobalConfig) {
    this.config = Object.assign({}, this.config, data);
    this.emit('update', this.config);
    return this.config;
  }

  reset(data: GlobalConfig = {}) {
    this.config = Object.assign({}, defaultConfig, data);
    this.emit('update', this.config);
    return this.config;
  }
}
