
export type CustomKeys = 'full_name';

export default class Person {
  id: number;
  person_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  status: string;

  constructor(data: {[key: string]: any}) {
    data = data || {};
    
    this.id = data.id;
    this.person_id = data.person_id || '';
    this.first_name = data.first_name || '';
    this.last_name = data.last_name || '';
    this.email = data.email || '';
    this.phone = data.phone || '';
    this.status = data.status;
  }

  get(key: keyof this | CustomKeys): any {
    switch(key){
      case 'full_name':
        return `${this.first_name} ${this.last_name}`;

      default:
        return this[key];
    }
  }
}