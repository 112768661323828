
import { GlobalConfig } from './types';

const defaultConfig: GlobalConfig = {
  title: '',
  showBackButton: false,
  loading: false
};

export default defaultConfig;
