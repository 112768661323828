
import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  iconWrapper: {
    color: theme.palette.success.main,
    fontSize: 120,
    textAlign: 'center'
  }
});
