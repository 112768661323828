
import { useState, useEffect } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { shoppingListsService } from '../../services/shopping-lists';
import ShoppingList from '../../models/ShoppingList';

export default function useShoppingLists(page = 1, status = 'added', params?: any): [ShoppingList[], boolean, Error|string, {total_count: number, count: number, has_next: boolean, page: number}] {
  const [shoppingLists, setShoppingLists] = useState<ShoppingList[]>([]);
  const [paginationInfo, setPaginationInfo] = useState({ total_count: 0, count: 20, has_next: true, page: 0 });
  const [reLoaderFlag, setReloaderFlag] = useState(0);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  useEffect(()=>{
    (()=>reLoaderFlag)();

    let active = true;

    (async ()=>{
      try{
        setLoading(true);
        const data = await shoppingListsService.fetchAllPaginated(page, status, params);
        data.items.sort((a,b)=>((new Date(b.created_at)).getTime() - (new Date(a.created_at)).getTime()));
        
        if(!active)
          return;

        setMessage(data.items.length === 0 ? 'Nothing found.' : '');
        setShoppingLists(data.items);
        setPaginationInfo({
          total_count: data.total_count,
          count: data.count,
          has_next: data.has_next,
          page: data.page
        });
      }
      catch(err){
        notify(err);
        setMessage('Failed to load shopping lists.');
      }
      finally{
        setLoading(false);
      }
    })();

    return ()=>{ active=false; };

  }, [notify, page, status, params, reLoaderFlag]);

  useEffect(()=>{
    function addShoppingList(shoppingList: ShoppingList){
      setShoppingLists(shoppingLists=>([...shoppingLists, shoppingList]));
      setMessage('');
    }

    function removeShoppingList(shoppingListId: number) {
      setReloaderFlag(Date.now());
      setShoppingLists(shoppingLists=>shoppingLists.filter(v=>v.id !== shoppingListId));
    }

    function removeShoppingLists(shoppingListIds: number[]) {
      setReloaderFlag(Date.now());
      setShoppingLists(shoppingLists=>shoppingLists.filter(v=>shoppingListIds.indexOf(v.id) === -1));
    }

    function updateShoppingList(updatedShoppingList: ShoppingList, shoppingListId: number) {
      setShoppingLists(shoppingLists=>{
        const index = shoppingLists.findIndex(p=>p.id === shoppingListId);
        const updatedList = [...shoppingLists];
        updatedList[index] = updatedShoppingList;
        return updatedList;
      });
    }

    shoppingListsService.on('shopping-list-created', addShoppingList);
    shoppingListsService.on('shopping-list-deleted', removeShoppingList);
    shoppingListsService.on('shopping-lists-deleted', removeShoppingLists);
    shoppingListsService.on('shopping-list-updated', updateShoppingList);

    return ()=>{
      shoppingListsService.off('shopping-list-created', addShoppingList);
      shoppingListsService.off('shopping-list-deleted', removeShoppingList);
      shoppingListsService.off('shopping-lists-deleted', removeShoppingLists);
      shoppingListsService.off('shopping-list-updated', updateShoppingList);
    }

  }, []);

  return [shoppingLists, loading, message, paginationInfo];
}
