
import React, { useRef, useState } from 'react';
import authService from '../../services/authentication';
import Person from '../../models/Person';
import useCurrentPerson from '../../hooks/use-current-person';
import { useNotifier } from '../../providers/NotifierProvider';
import ProfileInfoForm, { ProfileInfo, ProfileInfoFormRef } from '../../forms/ProfileInfoForm';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';

const ProfileInfoSection: React.FC = function() {
  const currentPerson = useCurrentPerson() as Person;
  const profileInfoForm = useRef<ProfileInfoFormRef>(null);
  const notify = useNotifier();
  const [editMode, setEditMode] = useState(false);
  const [saving, setSaving] = useState(false);

  async function handleSave(data: ProfileInfo) {
    try{
      setSaving(true);
      await authService.updateCurrentPerson(data);
      setEditMode(false);
      notify('Profile updated.');
    }
    catch(err){
      notify(err);
    }
    finally{
      setSaving(false);
    }
  }

  return (
    <section>
      <Box p={2}>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <Typography variant="h6">Profile information</Typography>
            <Typography variant="subtitle2" color="textSecondary">View/Update profile information</Typography>
          </Grid>
          {!editMode && (
            <Grid item>
              <IconButton
                onClick={()=>setEditMode(true)}
                size="small"
              >
                <EditIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
      </Box>
      {!editMode && (
        <Box p={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="textSecondary">NAME</Typography>
              <Typography variant="body1">{ currentPerson?.get('full_name') }</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="caption" color="textSecondary">PHONE</Typography>
              <Typography variant="body1">{ currentPerson?.get('phone') }</Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      {editMode && (
        <Box p={2}>
          <ProfileInfoForm
            ref={profileInfoForm}
            initialValues={currentPerson}
            onSubmit={handleSave}
          />
        </Box>
      )}

      {editMode && !saving && (
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item>
              <Button
                size="small"
                onClick={()=>setEditMode(false)}
              >Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                size="small"
                disabled={saving}
                onClick={()=>profileInfoForm.current?.submitForm()}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}

      {editMode && saving && (
        <Box p={2}>
          <CircularProgress color="primary" size={24} />
        </Box>
      )}
    </section>
  )
};

export default ProfileInfoSection;
