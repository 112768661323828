
import React, { useState, useRef } from 'react';
import { placesService } from '../../services/places';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import Place from '../../models/Place';
import { CardsViewProps } from './types';
import PdfLinkDialog, { PdfLinkDialogRef } from '../../dialogs/PdfLinkDialog';
import MenuButton from '../../components/MenuButton';
import PlaceCard from './PlaceCard';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';

const CardsView: React.FC<CardsViewProps> = function(props) {
  const { places, disabled } = props;

  const pdfLinkDialog = useRef<PdfLinkDialogRef>(null);
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [selectMode, setSelectMode] = useState(false);
  const [pdfUri, setPdfUri] = useState('');
  const [selection, setSelection] = useState<Place[]>([]);
  const [generatingLabels, setGeneratingLabels] = useState(false);

  function handleCardSelectionChange(selected: boolean, place: Place) {
    setSelection(selection=>{
      if(selected)
        return [...selection, place];
      else
        return selection.filter(family=>family.id !== place.id);
    });
  }

  async function handleGenerateLables(templateType: 'single' | 'sheet') {
    try{
      if(!selection.length)
        throw new Error('Please select one or more places to print labels for.');

      if(!(await confirm('Generate labels', `Generating labels for ${selection.length} place(s).`)))
        return;

      setGeneratingLabels(true);
      const labelsPdf = await placesService.generateLabels(selection, templateType);
      setPdfUri(labelsPdf);
      pdfLinkDialog.current?.openDialog();
      notify('Labels generated.');
      setSelection([]);
      setSelectMode(false);
    }
    catch(err){
      notify(err);
    }
    finally{
      setGeneratingLabels(false);
    }
  }

  return (
    <>
      <Box pb={2}>
        <Grid container spacing={1} alignItems="center" justify="flex-start">
          {!selectMode && places.length > 0 && (
            <Grid item>
              <Button
                color="primary"
                variant="contained"
                disabled={disabled || generatingLabels}
                onClick={()=>setSelectMode(true)}
              >
                Select
              </Button>
            </Grid>
          )}

          {selectMode && (
            <>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={disabled || generatingLabels}
                  onClick={()=>{setSelectMode(false); setSelection([]);}}
                >
                  Done
                </Button>
              </Grid>
              {selection.length < places.length && (
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disabled || generatingLabels}
                    onClick={()=>setSelection(places)}
                  >
                    Select all
                  </Button>
                </Grid>
              )}
              {selection.length > 0 && (
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={disabled || generatingLabels}
                    onClick={()=>setSelection([])}
                  >
                    Deselect all
                  </Button>
                </Grid>
              )}
              <Grid item>
                <Button
                  variant="outlined"
                  onClick={()=>handleGenerateLables('sheet')}
                  disabled={disabled || generatingLabels || !selection.length}
                >
                  Generate labels
                </Button>

                {false && <MenuButton
                  menuItems={[
                    { label: 'Single label template (roll)', onClick: ()=>handleGenerateLables('single') },
                    { label: 'Letter, 30 labels sheet template', onClick: ()=>handleGenerateLables('sheet') },
                  ]}
                  variant="outlined"
                  onClick={()=>handleGenerateLables('sheet')}
                  disabled={disabled || generatingLabels || !selection.length}
                >
                  Generate labels
                </MenuButton>}
              </Grid>
            </>
          )}
          {!!pdfUri && (
            <Grid item>
              <Link
                href={pdfUri}
                target="_BLANK"
                underline="none"
              >
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<OpenInBrowserIcon />}
                  disabled={generatingLabels}
                >
                  Open labels document
                </Button>
              </Link>
            </Grid>
          )}
          {generatingLabels && (
            <Grid item>
              <CircularProgress size={24} />
            </Grid>
          )}
        </Grid>
      </Box>

      <Grid container spacing={1}>
        {places.map(place=>(
          <Grid key={''+place.id} item xs={12} sm={4} md={3}>
            <PlaceCard
              place={place}
              selected={selection.indexOf(place) > -1}
              selectMode={selectMode}
              onChange={handleCardSelectionChange}
              disabled={disabled || generatingLabels}
            />
          </Grid>
        ))}
      </Grid>

      <PdfLinkDialog
        ref={pdfLinkDialog}
        title="Place labels document"
        pdfUri={pdfUri}
      />
    </>
  );
}

export default CardsView;
