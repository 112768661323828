
import { useRef, useEffect, useState, Ref } from 'react';

const useScrollPosition = (): [('top' | 'bottom' | 'middle' | null), Ref<HTMLDivElement>] => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<'top' | 'bottom' | 'middle' | null>(null);

  useEffect(()=>{
    const containerEl = containerRef.current;
    const observer = new MutationObserver(()=>{
      if(containerEl?.clientHeight !== containerEl?.scrollHeight){
        const scrollTop = containerEl?.scrollTop || 0;
        const scrollHeight = containerEl?.scrollHeight || 0;
        const clientHeight = containerEl?.clientHeight || 0;

        if(scrollTop === 0)
          setPosition('top');
        else if(scrollTop === scrollHeight - clientHeight)
          setPosition('bottom');
        else if(scrollHeight === clientHeight)
          setPosition(null);
        else
          setPosition('middle');
      }
    });

    containerEl && observer.observe(containerEl, { subtree: true, childList: true });

    const handleScroll = (ev: any) => {
      const scrollTop = ev.target?.scrollTop || 0;
      const scrollHeight = ev.target?.scrollHeight || 0;
      const clientHeight = ev.target?.clientHeight || 0;

      if(scrollTop === 0)
        setPosition('top');
      else if(scrollTop === scrollHeight - clientHeight)
        setPosition('bottom');
      else
        setPosition('middle');
    };

    containerEl?.addEventListener('scroll', handleScroll);
    return ()=>{
      containerEl?.removeEventListener('scroll', handleScroll);
      observer.disconnect();
    };
  }, []);

  return [position, containerRef];
};

export default useScrollPosition;
