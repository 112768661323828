
import React from 'react';
import usePeople from '../../hooks/use-people';
import useGlobalConfig from '../../hooks/use-global-config';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import InfoMessage from '../../components/InfoMessage';
import PersonRow from './PersonRow';
import InvitePersonPanel from '../../containers/invite-person-panel/InvitePersonPanel';

const PeoplePage: React.FC = function() {
  const [people, loading, message] = usePeople();
  
  useGlobalConfig({ title: 'People', loading });

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} lg={9}>
            <Paper>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>STATUS</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>NAME</TableCell>
                      <TableCell>EMAIL</TableCell>
                      <TableCell />
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {people.map(person=>(
                      <PersonRow key={''+person.id} person={person} />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              {!loading && !!message && (
                <Box p={2}>
                  <InfoMessage>{ message }</InfoMessage>
                </Box>
              )}
            </Paper>
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <InvitePersonPanel />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default PeoplePage;
