
export type MomentTrackIdTypes = 'activity' | 'place' | 'person' | 'product' | 'document' | 'transaction';

export enum MomentTrackIdLengths {
  activity = 9,
  place = 11,
  person = 17,
  product = 25,
  document = 32,
  transaction = 36
}
