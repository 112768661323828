
import React, { useState } from 'react';
import clsx from 'clsx';
import authService from '../../services/authentication';
import { makeStyles, /*withStyles,*/ Theme } from '@material-ui/core/styles';
import { useMediaQuery } from '@material-ui/core';
import useAuthentication from '../../hooks/use-authentication';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import { NavbarProps } from './types';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';
import HomeOutlineIcon from '@material-ui/icons/HomeOutlined';
import SettingsOutlineIcon from '@material-ui/icons/SettingsOutlined';
import LockOutlineIcon from '@material-ui/icons/LockOutlined';
import BusinessIcon from '@material-ui/icons/Business';
// import PrintIcon from '@material-ui/icons/Print';
// import PrintOutlinedIcon from '@material-ui/icons/PrintOutlined';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import AuthenticationControl from '../AuthenticationControl';
import { ReactComponent as PlaceIcon } from '@ordertrack/base/assets/icons/place.svg';
import { ReactComponent as PersonIcon } from '@ordertrack/base/assets/icons/person.svg';
import { ReactComponent as ProductIcon } from '@ordertrack/base/assets/icons/product.svg';
import styles, { /*listItemStyles*/ } from './styles';

const StyledListItem = ListItem// withStyles(listItemStyles)(ListItem);

const useStyles = makeStyles(styles);

const Navbar: React.FC<NavbarProps> = function(props) {
  const {
    hideAuthenticationControl = false,
    hideDrawer = false,
    // hideTitle = false,
    showLoader = false,
    title = '',
    showBackButton = false

  } = props;

  const classes = useStyles(props);
  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });
  const [drawerOpen, setDrawerOpen] = useState(isSmallScreen ? false : true);
  const location = useLocation();
  const history = useHistory();
  const authenticationDetails = useAuthentication();
  const isAuthenticated = !!authenticationDetails;

  const confirm = useConfirmDialog();

  function getTitle() {
    switch(location.pathname.split('/')[1]){
      case 'dashboard': return 'Dashboard';
      case 'settings': return 'Settings';
      case 'people': return 'People';
      case 'i-am-empty': return 'I am empty';
      case 'custom-order': return 'Custom Order';
      case 'move-bin-family': return 'Move bin family';
      case 'vendors': return 'Vendors';
      case 'printers': return 'Printers';
      case 'places': return 'Places';
      case 'products': return 'Products';
      case 'bin-families': return 'Bin families';
      case 'order-plates': return 'Order Plates';
      case 'shopping-list': return 'Shopping list';
      default: return '';
    }
  }

  function toggleDrawer() {
    setDrawerOpen(isOpen=>!isOpen);
  }

  async function handleLogout() {
    if(await confirm('Logout', 'Are you sure?'))
      authService.logout();
  }

  return (
    <>
      <AppBar position="fixed" color="inherit" elevation={2}>
        <Toolbar className={clsx(classes.toolbar, { [classes.toolbarShift]: drawerOpen && !hideDrawer })}>
          {!drawerOpen && !hideDrawer && !showBackButton && (
            <IconButton className={classes.menuIcon}
              edge="start"
              onClick={toggleDrawer}
            >
              <MenuIcon />
            </IconButton>
          )}

          {showBackButton && (
            <IconButton className={classes.backButton} edge="start" onClick={()=>history.go(-1)}>
              <BackIcon />
            </IconButton>
          )}

          {hideDrawer && !title && (
            <Typography variant="h5" color="primary" className={classes.appTitle}><b>Order Track</b></Typography>
          )}

          <Typography variant="h6" className={classes.pageTitle}>{ title }</Typography>

          {/* <div className={classes.toolbarSpace} /> */}

          {!hideAuthenticationControl && (
            <div>
              <AuthenticationControl
                authenticated={isAuthenticated}
                person={authenticationDetails?.person as any}
                onLogout={handleLogout}
              />
            </div>
          )}
        </Toolbar>

        {showLoader && (
          <LinearProgress />
        )}
        
      </AppBar>
      {!hideDrawer && (
        <Drawer className={clsx(classes.drawer, {[classes.drawerShift]: drawerOpen})}
          classes={{ paper: classes.drawerPaper }}
          variant={isSmallScreen ? 'temporary' : 'persistent'}
          anchor="left"
          open={drawerOpen}
          onClose={toggleDrawer}
        >
          <AppBar color="primary" elevation={0}>
            <Toolbar>
              <Typography variant="h6">ORDERTRACK</Typography>
              <div className={classes.toolbarSpace} />
              <IconButton onClick={toggleDrawer} size="small" color="inherit" edge="end">
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          <div onClick={()=>isSmallScreen && toggleDrawer()}>
            <List disablePadding>
              <StyledListItem component={NavLink} button to="/dashboard" selected={getTitle() === 'Dashboard'} >
                <ListItemIcon>{getTitle() === 'Dashboard' ? <HomeIcon /> : <HomeOutlineIcon />}</ListItemIcon>
                <ListItemText>Dashboard</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/people" selected={getTitle() === 'People'}>
                <ListItemIcon><SvgIcon><PersonIcon /></SvgIcon></ListItemIcon>
                <ListItemText>People</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/settings" selected={getTitle() === 'Settings'}>
                <ListItemIcon>{getTitle() === 'Settings' ? <SettingsIcon /> : <SettingsOutlineIcon />}</ListItemIcon>
                <ListItemText>Settings</ListItemText>
              </StyledListItem>
              <StyledListItem button onClick={handleLogout}>
                <ListItemIcon><LockOutlineIcon /></ListItemIcon>
                <ListItemText>Logout</ListItemText>
              </StyledListItem>
            </List>

            <Box p={2} mt={2}>
              <Typography variant="subtitle2" color="textSecondary">SYSTEM</Typography>
            </Box>

            <List disablePadding>
              <StyledListItem component={NavLink} button to="/shopping-list" selected={getTitle() === 'Shopping list'}>
                <ListItemIcon>{getTitle() === 'Shopping list' ? <ShoppingCartIcon /> : <ShoppingCartOutlinedIcon />}</ListItemIcon>
                <ListItemText>Shopping List</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/vendors" selected={getTitle() === 'Vendors'}>
                <ListItemIcon><BusinessIcon /></ListItemIcon>
                <ListItemText>Vendors</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/products" selected={getTitle() === 'Products'}>
                <ListItemIcon><SvgIcon><ProductIcon /></SvgIcon></ListItemIcon>
                <ListItemText>Products</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/bin-families" selected={getTitle() === 'Bin families'}>
                <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
                <ListItemText>Bin Families</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/order-plates" selected={getTitle() === 'Order Plates'}>
                <ListItemIcon><ShoppingBasketIcon /></ListItemIcon>
                <ListItemText>Create Order Plates</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/places" selected={getTitle() === 'Places'}>
                <ListItemIcon><SvgIcon><PlaceIcon /></SvgIcon></ListItemIcon>
                <ListItemText>Places</ListItemText>
              </StyledListItem>
              {/* <StyledListItem component={NavLink} button to="/printers" selected={getTitle() === 'Printers'}>
                <ListItemIcon>{getTitle() === 'Printers' ? <PrintIcon /> : <PrintOutlinedIcon />}</ListItemIcon>
                <ListItemText>Printers</ListItemText>
              </StyledListItem> */}
            </List>

            <Box p={2} mt={2}>
              <Typography variant="subtitle2" color="textSecondary">TOOLS</Typography>
            </Box>

            <List disablePadding>
              <StyledListItem component={NavLink} button to="/i-am-empty" selected={getTitle() === 'I am empty'}>
                <ListItemText>Scan empty to auto add</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/custom-order" selected={getTitle() === 'Custom Order'}>
                <ListItemText>Scan to order specific amount</ListItemText>
              </StyledListItem>
              <StyledListItem component={NavLink} button to="/move-bin-family" selected={getTitle() === 'Move bin family'}>
                <ListItemText>Move bin family</ListItemText>
              </StyledListItem>
            </List>
          </div>
        </Drawer>
      )}
    </>
  );
};

export default Navbar;
