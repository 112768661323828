
import OrderPlate from './OrderPlate';

export const createOrderPlate = (data: any) => {
  console.log(data);
  return new OrderPlate(data);
};

// Other exports.
export { default as OrderPlate } from './OrderPlate';
