
import React, { useState } from 'react';
import moment from 'moment';
import { momentTrackService } from '../../services/moment-track';
import { shoppingListsService } from '../../services/shopping-lists';
import ShoppingList from '../../models/ShoppingList';
import Product from '../../models/Product';
import Vendor from '../../models/Vendor';
import Place from '../../models/Place';
import Bin from '../../models/Bin';
import { DataGridViewProps } from './types';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import { DataGrid, ColDef, RowData } from '@material-ui/data-grid';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@material-ui/icons/GetApp';

const columnDefinitions: ColDef[] = [
  {
    field: 'productName',
    headerName: 'Product',
    valueGetter: (params)=>(params.getValue('product') as Product)?.name,
    width: 250
  },
  {
    field: 'quantity',
    headerName: 'Qty'
  },
  {
    field: 'vendorName',
    headerName: 'Vendor',
    valueGetter: params=>(params.getValue('vendor') as Vendor)?.name,
    width: 150
  },
  {
    field: 'created_at',
    headerName: 'Created at',
    valueFormatter: params=>moment.utc(params.getValue('created_at') as string).local().format('MMM DD, YYYY @ hh:mma'),
    width: 200
  },
  {
    field: 'bin',
    headerName: 'Bin (product QR)',
    valueGetter: params=>momentTrackService.generateUrl((params.getValue('bin') as Bin)?.id, 'product'),
    width: 290
  },
  {
    field: 'placeName',
    headerName: 'Place',
    valueGetter: params=>(params.getValue('location') as Place)?.name,
    width: 150
  },
  {
    field: 'placeUrl',
    headerName: 'Place (url)',
    valueGetter: params=>momentTrackService.generateUrl((params.getValue('location') as Bin)?.id, 'place'),
    width: 200
  }
];

const DataGridView: React.FC<DataGridViewProps> = function(props) {
  const {
    containerHeight = 700,
    rows,
    DataGridProps = {}
  } = props;

  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const [selection, setSelection] = useState<RowData[]>([]);

  async function handleCsvExport() {
    try{
      if(selection.length === 0)
        throw new Error('No rows has been selected for export.');

      if(await confirm('Continue?', `Exporting ${selection.length} rows to CSV.`)){
        shoppingListsService.downloadCsv(selection as ShoppingList[]);
      }
    }
    catch(err){
      notify(err);
    }
  }

  return (
    <>
      <Box p={2}>
        <Grid container spacing={2} justify="flex-end">
          <Grid item>
            <Button
              onClick={handleCsvExport}
              color="primary"
              disabled={selection.length === 0}
              startIcon={<DownloadIcon />}
            >Export CSV</Button>
          </Grid>
        </Grid>
      </Box>
      <Box height={containerHeight}>
        <DataGrid
          {...DataGridProps}
          checkboxSelection
          onSelectionChange={newSelection=>setSelection(newSelection.rows)}
          rows={rows}
          columns={columnDefinitions}
        />
      </Box>
    </>
  );
};

export default DataGridView;
