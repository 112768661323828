
import { Theme, createStyles } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';

export default (theme: Theme) => createStyles({
  pageNavbar: {
    backgroundColor: blueGrey['100']
  }
});

export const dataGridStyles = (theme: Theme) => createStyles({
  spacing: {
    flex: 1
  }
});
