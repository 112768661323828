
import { ExportToCsv } from 'export-to-csv';
import moment from 'moment';
import apiClient, { ApiResponse, PaginatedData } from '../api-client';
import { authenticationStore } from '../store';
import { momentTrackService } from '../moment-track';
import ApiService from '../api';
import ShoppingList from '../../models/ShoppingList';

export default class ShoppingListsService extends ApiService<ShoppingList> {
  private csvExporter = new ExportToCsv({
    filename: 'shopping-list-ordertrack',
    showLabels: true,
    useKeysAsHeaders: true
  });

  constructor() {
    super(
      ()=>`/api/${authenticationStore.get('org_slug')}/shopping_list`,
      'shopping-list',
      data=>new ShoppingList(data)
    );
  }

  async fetchAllPaginated(page = 1, status = 'added', params?: any) {
    const url = this.resourcePath();
    const response = await apiClient.get<ApiResponse<PaginatedData<ShoppingList>>>(url, {
      params: { page, status, ...(params || {}) }
    });

    return {
      ...response.data.data,
      items: response.data.data.items.map(this.deserialize)
    };
  }

  async deleteMany(shoppingLists: ShoppingList[]) {
    const url = this.resourcePath();
    const ids = shoppingLists.map(item=>item.id);
    await apiClient.delete(url, {
      params: {
        ids: ids.join(',')
      }
    });

    this.emit('shopping-lists-deleted', ids);
  }

  downloadCsv(shoppingLists: ShoppingList[]) {
    this.csvExporter.generateCsv(shoppingLists.map(item=>({
      id: item.id,
      bin_family_id: item.bin.bin_family_id,
      bin_id: item.bin_id,
      document_url: momentTrackService.generateUrl(item.bin_id, 'document'),
      product_url: momentTrackService.generateUrl(item.bin_id, 'product'),
      product_id: item.product_id,
      product: item.product.name,
      product_description: item.product.description,
      part_number: item.product.part_number,
      erp_part_number: item.product.erp_part_number,
      preferred_vendor_part_number: item.product.preferred_vendor_part_number,
      vendor_id: item.vendor_id,
      vendor: item.vendor.name,
      erp_number: item.vendor.erp_number,
      place_id: item.location_id,
      place_url: momentTrackService.generateUrl(item.location_id, 'place'),
      place: item.location.name,
      activity_id: item.activity_id,
      activity_url: momentTrackService.generateUrl(item.activity_id, 'activity'),
      default_quantity: item.bin.bin_family.default_quantity,
      quantity: item.quantity,
      user_id: item.user_id,
      person_url: momentTrackService.generateUrl(item.user_id, 'person'),
      timestamp_unix: moment.utc(item.created_at).local().unix()
    })));
  }
}
