
import ApiService from './ApiService';
import Person from '../../models/Person';
import Product from '../../models/Product';
import Bin from '../../models/Bin';
import Vendor from '../../models/Vendor';
import Printer from '../../models/Printer';
import { authenticationStore } from '../store';

export { default } from './ApiService';

export const peopleService = new ApiService<Person>(
  ()=>`/api/${authenticationStore.get('org_slug')}/users`,
  'user',
  data=>new Person(data)
);

export const printersService = new ApiService<Printer>(
  ()=>`/api/${authenticationStore.get('org_slug')}/printers`,
  'printer',
  data=>new Printer(data)
);

export const vendorsService = new ApiService<Vendor>(
  ()=>`/api/${authenticationStore.get('org_slug')}/vendors`,
  'vendor',
  data=>new Vendor(data)
);

export const productsService = new ApiService<Product>(
  ()=>`/api/${authenticationStore.get('org_slug')}/products`,
  'product',
  data=>new Product(data)
);

export const binsService = new ApiService<Bin>(
  ()=>`/api/${authenticationStore.get('org_slug')}/bins`,
  'bin',
  data=>new Bin(data)
);
