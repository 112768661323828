
import React from 'react';
import useAuthentication from './hooks/use-authentication';
import { BrowserRouter, Route, Switch, Redirect, RouteProps } from 'react-router-dom';
import MainLayout from './containers/layouts/MainLayout';
// import LandingPage from '../pages/LandingPage';
import AcceptInvitePage from './containers/AcceptInvitePage';
import LoginPage from './containers/login-page/LoginPage';
import ForgotPasswordPage from './containers/forgot-password-page/ForgotPasswordPage';
import ResetPasswordPage from './containers/reset-password-page/ResetPasswordPage';
import DashboardPage from './pages/DashboardPage';
import PeoplePage from './pages/PeoplePage';
import IAmEmptyPage from './pages/IAmEmptyPage';
import CustomOrderPage from './pages/CustomOrderPage';
import SettingsPage from './pages/SettingsPage';
import VendorsPage from './pages/VendorsPage';
import PrintersPage from './pages/PrintersPage';
import PlacesPage from './pages/PlacesPage';
import PlacePage from './pages/PlacePage';
import ProductsPage from './pages/ProductsPage';
import ProductPage from './pages/ProductPage';
import BinFamiliesPage from './pages/BinFamiliesPage';
import BinFamilyPage from './pages/BinFamilyPage';
import BinPage from './pages/BinPage';
import OrderPlates from './OrderPlates';
import ShoppingListPage from './pages/ShoppingListPage';
import MoveBinFamilyPage from './pages/MoveBinFamilyPage';
import ShoppingListHistoryPage from './pages/ShoppingListHistoryPage';
import HeyTherePage from './pages/HeyTherePage';

interface AuthAwareRouteProps extends RouteProps {
  requireAuth?: boolean;
  redirectTo: string;
}

const AuthAwareRoute: React.FC<AuthAwareRouteProps> = function({redirectTo, requireAuth, ...props}) {
  const authenticated = !!useAuthentication();
  const state: any = props.location?.state;

  if((authenticated && requireAuth) || (!authenticated && !requireAuth))
    return <Route {...props}/>;
  else
    return <Redirect to={{pathname: state && state.from ? state.from.pathname : redirectTo, state: {from: props.location}}} />;
};

const MainRouter: React.FC = function() {
  return (
    <BrowserRouter>
      <Switch>
        <Route path={['/login', '/forgot-password', '/reset-password', '/accept-invite', '/hey-there']}>
          <MainLayout hideNavbar>
            <Switch>
              <AuthAwareRoute path="/login" redirectTo="/" component={LoginPage} />
              <AuthAwareRoute path="/forgot-password" redirectTo="/" component={ForgotPasswordPage} />
              <AuthAwareRoute path="/reset-password" redirectTo="/" component={ResetPasswordPage} />
              <AuthAwareRoute path="/accept-invite" redirectTo="/" component={AcceptInvitePage} />
              <AuthAwareRoute path="/hey-there" requireAuth redirectTo="/login" component={HeyTherePage} />
            </Switch>
          </MainLayout>
        </Route>
        <Route exact path={['/', '/people', '/settings', '/dashboard', '/reports', '/i-am-empty', '/order-plates', '/custom-order', '/move-bin-family', '/vendors', '/shopping-list/history', '/shopping-list', '/printers', '/places/:placeId', '/places', '/products/:productId', '/products', '/bin-families', '/bin-families/:binFamilyId', '/bins/:binId']}>
          <MainLayout NavbarProps={{ hideTitle: true }}>
            <Switch>
              <AuthAwareRoute path="/people" requireAuth redirectTo="/login" component={PeoplePage} />
              <AuthAwareRoute path="/settings" requireAuth redirectTo="/login" component={SettingsPage} />
              <AuthAwareRoute path="/vendors" requireAuth redirectTo="/login" component={VendorsPage} />
              <AuthAwareRoute path="/printers" requireAuth redirectTo="/login" component={PrintersPage} />
              <AuthAwareRoute path="/places/:placeId" requireAuth redirectTo="/login" component={PlacePage} />
              <AuthAwareRoute path="/places" requireAuth redirectTo="/login" component={PlacesPage} />
              <AuthAwareRoute path="/products/:productId" requireAuth redirectTo="/login" component={ProductPage} />
              <AuthAwareRoute path="/products" requireAuth redirectTo="/login" component={ProductsPage} />
              <AuthAwareRoute path="/shopping-list/history" requireAuth redirectTo="/login" component={ShoppingListHistoryPage} />
              <AuthAwareRoute path="/shopping-list" requireAuth redirectTo="/login" component={ShoppingListPage} />
              <AuthAwareRoute path="/order-plates" requireAuth redirectTo="/login" component={OrderPlates} />
              <AuthAwareRoute path="/bin-families/:binFamilyId" requireAuth redirectTo="/login" component={BinFamilyPage} />
              <AuthAwareRoute path="/bins/:binId" requireAuth redirectTo="/login" component={BinPage} />
              <AuthAwareRoute path="/bin-families" requireAuth redirectTo="/login" component={BinFamiliesPage} />
              <AuthAwareRoute path="/move-bin-family" requireAuth redirectTo="/login" component={MoveBinFamilyPage} />
              <AuthAwareRoute path="/i-am-empty" requireAuth redirectTo="/login" component={IAmEmptyPage} />
              <AuthAwareRoute path="/custom-order" requireAuth redirectTo="/login" component={CustomOrderPage} />
              <AuthAwareRoute path={['/', '/dashboard']} requireAuth redirectTo="/login" component={DashboardPage} />
            </Switch>
          </MainLayout>
        </Route>
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  );
};

export default MainRouter;
