
export default class Place {
  id: number;
  name: string;
  parent_location_id: number;
  organization_id: number;
  active: boolean;
  created_at: string;

  constructor(data: {[key: string]: any}) {
    data = data || {};
    
    this.id = data.id;
    this.name = data.name;
    this.parent_location_id = data.parent_location_id;
    this.organization_id = data.organization_id;
    this.active = data.active;
    this.created_at = data.created_at;
  }
}
