
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useGlobalConfig from '../../hooks/use-global-config';
import { useProduct } from '../../hooks/use-products';
import InfoMessage from '../../components/InfoMessage';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';

const ProductPage: React.FC<RouteComponentProps<{productId: string}>> = function(props) {
  const { match } = props;
  const productId = Number(match.params.productId);
  const [product, loading, message] = useProduct(productId);

  useGlobalConfig({
    title: 'Product',
    showBackButton: true,
    loading
  });

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">{loading || !product ? '...' : product.name}</Typography>
            <Typography variant="subtitle2" color="textSecondary">Product</Typography>
          </Box>
          
          <Divider />

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{ message }</InfoMessage>
            </Box>
          )}

          {!!product && (
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">DESCRIPTION</Typography>
                  <Typography variant="body1">{ product.description }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">PART #</Typography>
                  <Typography variant="body1">{ product.part_number }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">ERP PART #</Typography>
                  <Typography variant="body1">{ product.erp_part_number }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">VENDOR</Typography>
                  <Typography variant="body1">{ product.preferred_vendor.name }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">VENDOR PART #</Typography>
                  <Typography variant="body1">{ product.preferred_vendor_part_number }</Typography>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
}

export default ProductPage;
