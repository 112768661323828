
import React, { forwardRef, useImperativeHandle, useMemo } from 'react';
import randomize from 'randomatic';
import validationSchema from './validation-schema';
import { useFormik } from 'formik';
import useVendors from '../../hooks/use-vendors';
import { ProductFormProps, ProductFormRef, ProductData } from './types';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import GrainIcon from '@material-ui/icons/Grain';

const ProductForm: React.ForwardRefRenderFunction<ProductFormRef, ProductFormProps> = function(props, ref) {
  const {
    initialValues = {},
    onSubmit = ()=>{},
    disabled

  } = props;

  const [vendors, loadingVendors] = useVendors();

  const vendorOptions = useMemo(()=>{
    return vendors.map(v=>({ label: v.name, value: v.id }));
  }, [vendors]);

  const formik = useFormik<ProductData>({
    initialValues: {
      name: initialValues.name || '',
      description: initialValues.description || '',
      part_number: initialValues.part_number || '',
      erp_part_number: initialValues.erp_part_number || '',
      preferred_vendor_id: initialValues.preferred_vendor_id,
      preferred_vendor_part_number: initialValues.preferred_vendor_part_number || '',
    },
    validationSchema,
    onSubmit
  });

  useImperativeHandle(ref, ()=>formik, [formik]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label="NAME"
            {...formik.getFieldProps('name')}
            error={formik.touched['name'] && !!formik.errors['name']}
            helperText={formik.errors['name']}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="standard"
            label="DESCRIPTION"
            {...formik.getFieldProps('description')}
            error={formik.touched['description'] && !!formik.errors['description']}
            helperText={formik.errors['description']}
            multiline
            rows={1}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="PART #"
            {...formik.getFieldProps('part_number')}
            helperText="Leave empty to auto generate."
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton size="small" onClick={()=>formik.setFieldValue('part_number', `${randomize('A', 5)}-${randomize('A0', 10)}`)}>
                    <GrainIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="ERP PART #"
            {...formik.getFieldProps('erp_part_number')}
            error={formik.touched['erp_part_number'] && !!formik.errors['erp_part_number']}
            helperText={formik.errors['erp_part_number']}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {loadingVendors && (<CircularProgress size={30} />)}
          {!loadingVendors && (
            <TextField
              variant="standard"
              label="VENDOR"
              {...formik.getFieldProps('preferred_vendor_id')}
              error={formik.touched['preferred_vendor_id'] && !!formik.errors['preferred_vendor_id']}
              helperText={formik.errors['preferred_vendor_id']}
              disabled={disabled}
              select
              SelectProps={{ native: true }}
            >
              <option value=""></option>
              {vendorOptions.map(opt=>(
                <option key={opt.label + ''} value={opt.value}>{ opt.label }</option>
              ))}
            </TextField>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            variant="standard"
            label="VENDOR PART #"
            {...formik.getFieldProps('preferred_vendor_part_number')}
            error={formik.touched['preferred_vendor_part_number'] && !!formik.errors['preferred_vendor_part_number']}
            helperText={formik.errors['preferred_vendor_part_number']}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default forwardRef(ProductForm);
