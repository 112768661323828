
import React, { useState, useRef } from 'react';
import { binFamiliesService } from '../../services/bin-families';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { useNotifier } from '../../providers/NotifierProvider';
import { BinFamilyCardProps } from './types';
import BinFamilyDialog, { BinFamilyDialogRef, BinFamilyData } from '../../dialogs/BinFamilyDialog';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import InfoIcon from '@material-ui/icons/InfoOutlined';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

const BinFamilyCard: React.FC<BinFamilyCardProps> = function(props) {
  const { binFamily, selected, selectMode, onChange = ()=>{}, disabled = false } = props;
  const confirm = useConfirmDialog();
  const notify = useNotifier();
  const binFamilyDialog = useRef<BinFamilyDialogRef>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const [loading, setLoading] = useState(false);

  function handleBinFamilyEdit() {
    setMenuAnchorEl(null);
    binFamilyDialog.current?.openDialog(true);
  }

  async function handleBinFamilySave(data: BinFamilyData) {
    await binFamiliesService.updateById(binFamily.id, data);
    notify('Bin family updated.');
  }

  async function handleBinFamilyDelete() {
    try{
      setMenuAnchorEl(null);

      if(await confirm('Are you sure?', `You are about to delete bin family "${binFamily.product.name}". This action cannot be undone.`)){
        setLoading(true);
        await binFamiliesService.deleteById(binFamily.id);
        notify('Bin family deleted.');
      }
      else
        return;
    }
    catch(err){
      notify(err as Error);
    }
    finally{
      setLoading(false);
    }
  }

  return (
    <Paper>
      <Box p={2}>
        <Grid container spacing={1} alignItems="center" wrap="nowrap">
          {selectMode && (
            <Grid item>
              <Checkbox
                size="small"
                checked={selected}
                disabled={disabled}
                onChange={(ev, checked)=>onChange(checked, binFamily)}
              />
            </Grid>
          )}
          <Grid item xs zeroMinWidth>
            <Link
              underline="none"
              noWrap
              variant="body1"
              color="textPrimary"
              component={RouterLink}
              to={`/bin-families/${binFamily.id}`}
              style={{ display: 'block' }}
            >
              { binFamily.product.name }
            </Link>
            {/* <Typography variant="body1" noWrap>{ binFamily.product.name }</Typography> */}
          </Grid>
          <Grid item>
            <IconButton
              disabled={loading}
              size="small"
              onClick={()=>binFamilyDialog.current?.openDialog()}
            >
              <InfoIcon />
            </IconButton>
          </Grid>
          {!loading && (
            <Grid item>
              <IconButton
                disabled={loading}
                size="small"
                edge="end"
                onClick={ev=>setMenuAnchorEl(ev.currentTarget)}
              >
                <MoreHorizIcon />
              </IconButton>
            </Grid>
          )}
          {loading && (
            <Grid item style={{ lineHeight: 0 }}>
              <CircularProgress size={30} />
            </Grid>
          )}
        </Grid>
      </Box>

      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={()=>setMenuAnchorEl(null)}
      >
        <MenuItem component={RouterLink} to={`/bin-families/${binFamily.id}`}>
          <ListItemIcon><OpenInNewIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Open</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleBinFamilyEdit}>
          <ListItemIcon><EditIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Edit</ListItemText>
        </MenuItem>
        <MenuItem onClick={handleBinFamilyDelete}>
          <ListItemIcon><DeleteIcon fontSize="small" /></ListItemIcon>
          <ListItemText>Delete</ListItemText>
        </MenuItem>
      </Menu>

      <BinFamilyDialog
        ref={binFamilyDialog}
        binFamily={binFamily}
        primaryActionText="Update"
        secondaryActionText="Close"
        primaryAction={handleBinFamilySave}
      />
    </Paper>
  );
};

export default BinFamilyCard;
