import React from "react";
import {IframeViewDialogProps} from "./types";
import Box from "@material-ui/core/Box";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

import {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import styles from "./styles";

const useStyles = makeStyles(styles);

const IframeViewDialog: React.FC<IframeViewDialogProps> = props => {
  const {title = "", iframeSrc, open, onClose, ...dialogProps} = props;

  const classes = useStyles();
  const [src, setSrc] = useState("");
  const [message, setMessage] = useState("");
  const [loadingSrc, setLoadingSrc] = useState(false);

  useEffect(() => {
    if (!open) return;

    (async () => {
      try {
        setMessage("");

        if (typeof iframeSrc === "function") {
          setLoadingSrc(true);
          const src = await iframeSrc();
          console.log(src);
          setSrc(src);
          setLoadingSrc(false);
        } else setSrc(iframeSrc);
      } catch (err) {
        setMessage((err as Error).message);
        setLoadingSrc(false);
      }
    })();
  }, [iframeSrc, open]);

  return (
    <Dialog
      disableBackdropClick={loadingSrc}
      disableEscapeKeyDown={loadingSrc}
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      classes={{paper: classes.dialogPaper}}
      {...dialogProps}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        {!!message && (
          <Box py={5} textAlign="center">
            <Typography color="error">{message}</Typography>
          </Box>
        )}
        {loadingSrc && (
          <Box py={5} textAlign="center">
            <CircularProgress size={24} />
          </Box>
        )}
        {!!src && (
          <iframe
            title="iframetitle"
            src={src}
            style={{
              border: "none",
              width: "100%",
              height: "100%",
            }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={loadingSrc}
          onClick={() => onClose && onClose({}, "escapeKeyDown")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default IframeViewDialog;
