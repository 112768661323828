
import React, { useRef, useState } from 'react';
// import { FiltersMap } from './types';
import randomize from 'randomatic';
import { binsService } from '../../services/api';
import { binFamiliesService } from '../../services/bin-families';
import useGlobalConfig from '../../hooks/use-global-config';
import useBinFamilies from '../../hooks/use-bin-families';
import { useNotifier } from '../../providers/NotifierProvider';
import useSearchFilter from '../../hooks/use-search-filter';
import BinFamilyDialog, { BinFamilyDialogRef, BinFamilyData } from '../../dialogs/BinFamilyDialog';
import PdfLinkDialog, { PdfLinkDialogRef } from '../../dialogs/PdfLinkDialog';
import CardsView from './CardsView';
import SearchBar from '../../components/SearchBar';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Fab from '@material-ui/core/Fab';
import InfoMessage from '../../components/InfoMessage';
import AddIcon from '@material-ui/icons/Add';
// import FiltersBar from './FiltersBar';

const BinFamiliesPage: React.FC = function() {
  // const [filters, setFilters] = useState<FiltersMap>({ place: '', vendor: '' });
  const [binFamilies, loading, message] = useBinFamilies();
  const [searchTerm, setSearchTerm] = useState('');
  const [lastBinLabelsUri, setLastBinLabelsUri] = useState('');
  const searchedBinFamilies = useSearchFilter(binFamilies, 'product', searchTerm);
  const binFamilyDialog = useRef<BinFamilyDialogRef>(null);
  const pdfLinkDialog = useRef<PdfLinkDialogRef>(null);
  const notify = useNotifier();

  useGlobalConfig({ title: 'Bin families', loading });

  async function handleBinFamilyCreate(data: BinFamilyData) {
    const binFamily = await binFamiliesService.create(data);
    
    notify('Creating bins...');
    
    const randomString = randomize('A0', 4);
    await Promise.all([
      binsService.create({ name: `BIN A-${binFamily.id}-${randomString}`, bin_family_id: binFamily.id }),
      binsService.create({ name: `BIN B-${binFamily.id}-${randomString}`, bin_family_id: binFamily.id })
    ]);

    notify('Generating labels...');
    // TODO: Prompt for template type to use.
    const labelsPdf = await binFamiliesService.generateLabels([binFamily]);

    setLastBinLabelsUri(labelsPdf);
    pdfLinkDialog.current?.openDialog();
    notify('New bin family created.');
  }

  return (
    <>
      <Container maxWidth={false}>
        <Box pt={2} pb={1}>
          <Grid container alignItems="center" spacing={2}>
            <Grid item xs md={4}>
              <SearchBar
                value={searchTerm}
                onChange={(ev)=>setSearchTerm(ev.target.value)}
                onClear={()=>setSearchTerm('')}
                disabled={loading}
              />
            </Grid>

            {/* <Grid item xs={12}>
              <FiltersBar
                value={filters}
                onChange={filters=>setFilters(filters)}
              />
            </Grid> */}

            <Grid item>
              <Fab
                size="medium"
                color="primary"
                disabled={loading}
                onClick={()=>binFamilyDialog.current?.openDialog(true)}
              >
                <AddIcon />
              </Fab>
            </Grid>
          </Grid>
        </Box>

        {!loading && !!message && (
          <Box py={1}>
            <InfoMessage>{message}</InfoMessage>
          </Box>
        )}

        <Box py={2}>
          <CardsView
            binFamilies={searchedBinFamilies}
            disabled={loading}
          />
        </Box>

        <BinFamilyDialog
          ref={binFamilyDialog}
          primaryActionText="Create"
          secondaryActionText="Cancel"
          primaryAction={handleBinFamilyCreate}
        />

        <PdfLinkDialog
          ref={pdfLinkDialog}
          title="Bin family labels"
          pdfUri={lastBinLabelsUri}
        />
      </Container>
    </>
  );
}

export default BinFamiliesPage;
