
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import useGlobalConfig from '../../hooks/use-global-config';
import { useBin } from '../../hooks/use-bins';
import InfoMessage from '../../components/InfoMessage';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

const BinPage: React.FC<RouteComponentProps<{binId: string}>> = function(props) {
  const { match } = props;

  useGlobalConfig({ title: 'Bin', showBackButton: true });

  const binId = Number(match.params.binId);
  const [bin, loading, message] = useBin(binId);

  return (
    <Container maxWidth={false}>
      <Box py={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6">{loading ? '...' : message || bin?.bin_family.product.name}</Typography>
            <Typography variant="subtitle2" color="textSecondary">Bin</Typography>
          </Box>
          <Divider />
          {loading && (
            <LinearProgress />
          )}

          {!loading && !!message && (
            <Box p={2}>
              <InfoMessage>{ message }</InfoMessage>
            </Box>
          )}

          {!!bin && (
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">BIN FAMILY</Typography>
                  <Link
                    component={RouterLink}
                    to={`/bin-families/${bin.bin_family_id}`}
                    color="textPrimary"
                    underline="always"
                    variant="body1"
                    display="block"
                    noWrap

                  >{ bin.bin_family.product.name }</Link>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">PRODUCT</Typography>
                  <Link
                    component={RouterLink}
                    to={`/products/${bin.bin_family.product_id}`}
                    color="textPrimary"
                    underline="always"
                    variant="body1"
                    display="block"
                    noWrap

                  >{ bin.bin_family.product.name }</Link>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">PART #</Typography>
                  <Typography variant="body1">{ bin.bin_family.product.part_number }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">ERP PART #</Typography>
                  <Typography variant="body1">{ bin.bin_family.product.erp_part_number }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">VENDOR</Typography>
                  <Typography variant="body1">{ bin.bin_family.preferred_vendor.name }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">VENDOR PART #</Typography>
                  <Typography variant="body1" noWrap>{ bin.bin_family.product.preferred_vendor_part_number }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">DEFAULT QTY.</Typography>
                  <Typography variant="body1">{ bin.bin_family.default_quantity }</Typography>
                </Grid>

                <Grid item xs={6} sm={4} lg={3}>
                  <Typography variant="caption" color="textSecondary">PLACE</Typography>
                  <Link
                    component={RouterLink}
                    to={`/places/${bin.bin_family.location_id}`}
                    color="textPrimary"
                    underline="always"
                    variant="body1"
                    display="block"

                  >{ bin.bin_family.location.name }</Link>
                </Grid>
              </Grid>
            </Box>
          )}
        </Paper>
      </Box>
    </Container>
  );
}

export default BinPage;
