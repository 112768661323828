
import React, { useState } from 'react';
import { FiltersMap } from './types';
import ShoppingList from '../../models/ShoppingList';
import { shoppingListsService } from '../../services/shopping-lists';
import { makeStyles } from '@material-ui/core/styles';
import useShoppingLists from '../../hooks/use-shopping-lists';
import useGlobalConfig from '../../hooks/use-global-config';
import { useNotifier } from '../../providers/NotifierProvider';
import { useConfirmDialog } from '../../providers/ConfirmDialogProvider';
import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ShoppingListDataGrid from './ShoppingListDataGrid';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Link from '@material-ui/core/Link';
import Button from '@material-ui/core/Button';
import InfoMessage from '../../components/InfoMessage';
import FiltersBar from './FiltersBar';
// import DownloadIcon from '@material-ui/icons/GetApp';
import HistoryIcon from '@material-ui/icons/History';
// import ShoppingListAccordian from './ShoppingListAccordian';
import styles from './styles';

const useStyles = makeStyles(styles);

const ShoppingListPage: React.FC = function() {
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState<FiltersMap>({});
  const [shoppingLists, loading, message, paginationInfo] = useShoppingLists(page, 'added', filters);
  const isSmallScreen = useMediaQuery((theme: Theme)=>theme.breakpoints.down('sm'), { noSsr: true });
  const [displayType] = useState(isSmallScreen ? 'data-grid' : 'data-grid');
  const [working, setWorking] = useState(false);
  const notify = useNotifier();
  const confirm = useConfirmDialog();

  const classes = useStyles();

  useGlobalConfig({ title: 'Shopping list', loading });

  // function generateCsv() {
  //   shoppingListsService.downloadCsv(shoppingLists);
  // }

  async function handleRemove(selection: ShoppingList[]) {
    try{
      if(selection.length === 0)
        throw new Error('No rows has been selected to remove.');

      if(await confirm('Continue?', `Removing ${selection.length} rows of data.`)){
        setWorking(true);
        await shoppingListsService.deleteMany(selection);
        notify('Items removed.');
      }
    }
    catch(err){
      notify(err);
    }
    finally{
      setWorking(false);
    }
  }

  return (
    <>
      <Paper square className={classes.pageNavbar} elevation={1}>
        <Container maxWidth={false}>
          <Box py={2}>
            <Link to="/shopping-list/history" underline="none" component={RouterLink}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<HistoryIcon />}
                size="small"
                disabled={loading}
              >
                History
              </Button>
            </Link>
          </Box>
        </Container>
      </Paper>

      {/* <Container maxWidth={false}>
        {!!shoppingLists.length && isSmallScreen && (
          <Box pt={2} pb={1}>
            <Button
              onClick={generateCsv}
              startIcon={<DownloadIcon />}
            >CSV</Button>
          </Box>
        )}
      </Container> */}
      <Container maxWidth={false}>
        <Box py={2}>
          <Grid container spacing={1}>
            {/* {displayType === 'cards' && (
              <Grid item xs={12} md={6}>
                {shoppingLists.map(shoppingList=>(
                  <ShoppingListAccordian
                    key={''+shoppingList.id}
                    shoppingList={shoppingList}
                  />
                ))}
              </Grid>
            )} */}

            <Grid item xs={12}>
              <FiltersBar
                value={filters}
                onChange={filters=>setFilters(filters)}
                disabled={loading}
              />
            </Grid>

            {displayType === 'data-grid' && shoppingLists.length > 0 && (
              <Grid item xs={12}>
                <ShoppingListDataGrid
                  rows={shoppingLists}
                  onRemove={handleRemove}
                  DataGridProps={{
                    columns: [], // Unnecessary but required. TODO: find a fix
                    rows: [], // Unnecessary but required. TODO: find a fix
                    rowCount: paginationInfo.total_count,
                    pageSize: paginationInfo.count,
                    rowsPerPageOptions: [paginationInfo.count],
                    loading: loading || working,
                    onPageChange: (page)=>setPage(page),
                    windowHeight: 600
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Box>

        {!loading && !!message && (
          <Box py={2}>
            <InfoMessage>{ message }</InfoMessage>
          </Box>
        )}
      </Container>
    </>
  );
};

export default ShoppingListPage;
