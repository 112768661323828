
import React from 'react';
import Box, { BoxProps } from '@material-ui/core/Box';

const Footer: React.FC<BoxProps> = props => (
  <Box
    component="footer"
    flexBasis={0}
    {...props}
  />
);

export default Footer;
