
import BinFamily from './BinFamily';

export default class Bin {
  id: number;
  name: string;
  bin_family_id: number;
  bin_family: BinFamily;
  organization_id: number;
  created_at: string;
  active: boolean;

  constructor(data: {[key: string]: any}) {
    data = data || {};

    this.id = data.id;
    this.name = data.name;
    this.bin_family_id = data.bin_family_id;
    this.bin_family = new BinFamily(data.bin_family);
    this.organization_id = data.organization_id;
    this.created_at = data.created_at; // TODO: Standardize dates manipulation
    this.active = data.active;
  }
}
