
import axios from 'axios';
import { authenticationStore } from '../store';
import CustomError from '../../errors/CustomError';

const API_HOST = process.env.REACT_APP_API_HOST;

const apiClient = axios.create({
  baseURL: API_HOST
});

// Adds Authorization header to the request.
apiClient.interceptors.request.use((config)=>{
  config.headers['Authorization'] = 'Bearer ' + authenticationStore.get('access_token');
  return config;
});

// Sends /refresh request upon 401.
apiClient.interceptors.response.use(undefined, async function(err){
  try{
    if(err.isAxiosError && err.response && err.response.status === 401){
      const refreshToken = authenticationStore.get('refresh_token');
      if(!refreshToken)
        throw err;
        
      const response = await axios.post(`${API_HOST}/auth/default/system/refresh`, undefined, {
        headers: {
          'Authorization': 'Bearer ' + refreshToken
        }
      });

      const data = response.data;
      authenticationStore.saveToken(data.access_token);

      return await axios.request({
        ...err.config,
        headers: {
          'Authorization': 'Bearer ' + data.access_token
        }
      });
    }
    else
      throw err;
  }
  catch(err){
    return Promise.reject(err);
  }
});

function parseError(err: any) {
  if(!err.isAxiosError || !err.response)
    return new CustomError(err.message, 'Oops...');

  let title: string;

  switch(err.response.status){
    case 404: title = 'Not found'; break;
    case 422: title = 'Validation failed'; break;
    default: title = 'Http error';
  }

  return new CustomError(err.response.data.message, title);
}

apiClient.interceptors.response.use(undefined, err=>{
  return Promise.reject(parseError(err));
});

export default apiClient;
export * from './types';
