
import React, { useRef, useEffect, useState, useCallback } from 'react';
import Bin from '../../models/Bin';
import { binsService } from '../../services/api';
import { shoppingListsService } from '../../services/shopping-lists';
import { momentTrackService } from '../../services/moment-track';
import { useNotifier } from '../../providers/NotifierProvider';
import useGlobalConfig from '../../hooks/use-global-config';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import QrCodeScanner, { QrCodeScannerRef } from '../../components/QrCodeScanner';
import { Divider } from '@material-ui/core';

const CustomOrderPage: React.FC = function() {
  useGlobalConfig({ title: 'Custom order' });

  const documentQrScanner = useRef<QrCodeScannerRef>(null);
  const [bin, setBin] = useState<Bin | null>(null);
  const [qty, setQty] = useState(0);
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);

  const sendOrder = useCallback(async function(){
    try{
      if(!Number(qty) || Number(qty) < 1)
        throw new Error('Cannot order this quantity.');

      setLoading(true);
      await shoppingListsService.create({
        product_id: bin?.bin_family.product_id,
        quantity: qty,
        vendor_id: bin?.bin_family.preferred_vendor_id,
        bin_id: bin?.id,
        location_id: bin?.bin_family.location_id
      });

      notify('Submitted');
      setBin(null);
      documentQrScanner.current?.openScanner();
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }

  }, [bin, notify, qty]);

  function handleSkip() {
    setBin(null);
    documentQrScanner.current?.openScanner();
  }

  async function handleDocumentScanResult(binId: string) {
    try{
      const idInfo = momentTrackService.parseId(binId);
      if(!idInfo || idInfo.type !== 'document')
        throw new Error('Invalid id. Please scan a bin document QR to proceed.');

      const documentId = Number(idInfo.id);
      if(!documentId || typeof documentId !== 'number')
        throw new Error('Looks like you scanned a wrong or an old format QR.');

      setLoading(true);
      const bin = await binsService.fetchById(documentId);
      
      setQty(bin.bin_family.default_quantity);
      setBin(bin);
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }
  }

  useEffect(()=>{
    documentQrScanner.current?.openScanner();
  }, []);

  return (
    <Box px={3} pt={6} >
      <Container maxWidth="sm" disableGutters>
        <Typography component="div" align="center">
          <Box pb={8}>
            <Typography variant="h6">Scan bin</Typography>
            {/* <Typography variant="subtitle2" color="textSecondary">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Error quas, tempore libero non, facere voluptates itaque nesciunt architecto.
            </Typography> */}
          </Box>

          {!!bin && (
            <Paper>
              <Typography component="div" align="left">
                <Box p={2}>
                  <Typography variant="subtitle1">Bin @ {bin.bin_family.location.name}</Typography>
                </Box>
                <Divider />
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="textSecondary">PRODUCT</Typography>
                      <Typography>{bin.bin_family.product.name}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="textSecondary">PART #</Typography>
                      <Typography>{bin.bin_family.product.part_number}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption" color="textSecondary">DEFAULT QTY.</Typography>
                      <Typography>{bin.bin_family.default_quantity}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box p={2}>
                  <TextField
                    label="QTY TO ORDER"
                    color="secondary"
                    type="number"
                    disabled={loading}
                    value={qty}
                    onChange={(ev: any)=>setQty(ev.target.value)}
                    inputProps={{ min: 1 }}
                  />
                </Box>
              </Typography>
            </Paper>
          )}

          <Box pt={2}>
            {!!bin && (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={loading}
                onClick={sendOrder}
              >
                Submit
              </Button>
            )}

            <QrCodeScanner
              ref={documentQrScanner}
              title="Scan bin document"
              buttonText="Scan"
              onResult={handleDocumentScanResult}
              disabled={loading}
              hidden={!!bin}
              ButtonProps={{
                variant: 'outlined',
                color: 'primary',
                size: 'large'
              }}
            />
          </Box>

          <Box py={4}>
            {loading && <CircularProgress />}
          </Box>

          <Box py={2}>
            <Grid container direction="column" spacing={1} justify="center">
              {!!bin && (
                <Grid item>
                  <Button size="small" onClick={handleSkip} disabled={loading} >Skip</Button>
                </Grid>
              )}
            </Grid>
          </Box>
        </Typography>
      </Container>
    </Box>
  );
};

export default CustomOrderPage;
