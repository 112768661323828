
import React, { useState, useCallback, FormEvent } from 'react';
import qs from 'qs';
import authService from '../../services/authentication';
import { makeStyles } from '@material-ui/core/styles';
import { useNotifier } from '../../providers/NotifierProvider';
import { RouteComponentProps } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';
import styles from './styles';

const useStyles = makeStyles(styles);

const ResetPasswordPage: React.FC<RouteComponentProps> = function(props) {
  const {
    location,
    history
  } = props;

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const notify = useNotifier();
  const classes = useStyles();

  const { token, org } = qs.parse(location.search, {ignoreQueryPrefix: true}) as { token: string, org: string };

  const handleSubmit = useCallback(async (ev: FormEvent)=>{
    try{
      ev.preventDefault();
      if(!password.trim() || password !== confirmPassword)
        throw new Error('Passwords doesn\'t match.');

      setLoading(true);
      await authService.resetPassword(password, token, org);
      
      notify('Password successfully changed.');
      history.replace('/login');
    }
    catch(err){
      notify(err);
    }
    finally{
      setLoading(false);
    }

  }, [password, confirmPassword, history, token, org, notify]);

  return (
    <div className={classes.root}>
      <Container maxWidth="xs">
        <Paper>
          {loading && <LinearProgress />}
          {!token && (
            <Box p={3}>
              <Typography variant="h6">Nothing found</Typography>
            </Box>
          )}
          {!!token && (
            <>
              <Box p={3}>
                <Typography variant="h6">Reset password</Typography>
                <Typography variant="subtitle2" color="textSecondary">Choose a new password for your account.</Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box p={3}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <TextField
                        label="New password"
                        type="password"
                        value={password}
                        disabled={loading}
                        onChange={ev=>setPassword(ev.target.value)}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        label="Confirm password"
                        type="password"
                        value={confirmPassword}
                        disabled={loading}
                        onChange={ev=>setConfirmPassword(ev.target.value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box p={3}>
                  <Grid container spacing={2} alignItems="center" justify="flex-end">
                    <Grid item>
                      <Link
                        to="/login"
                        component={RouterLink}
                        color="textPrimary"
                        variant="body1"
                        underline="always"
                      >
                        back to login
                      </Link>
                    </Grid>
                    <Grid item>
                      <Button type="submit" disabled={loading} variant="contained" color="primary">Continue</Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            </>
          )}
        </Paper>
      </Container>
    </div>
  );
};

export default ResetPasswordPage;
