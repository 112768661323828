
import { useState, useEffect, useCallback } from 'react';
import { useNotifier } from '../../providers/NotifierProvider';
import { peopleService } from '../../services/api';
import Person from '../../models/Person';

export default function usePeople(): [Person[], boolean, Error|string] {
  const [people, setPeople] = useState<Person[]>([]);
  const [message, setMessage] = useState<Error | string>('');
  const [loading, setLoading] = useState(true);
  const notify = useNotifier();

  const fetchPeople = useCallback(async ()=>{
    try{
      setLoading(true);
      const people = await peopleService.fetchAll({ include_inactive: true });
      people.sort((a,b)=>a.first_name.localeCompare(b.first_name));
      setMessage(people.length === 0 ? 'Nothing found.' : '');
      setPeople(people);
    }
    catch(err){
      notify(err);
      setMessage('Failed to load people.');
    }
    finally{
      setLoading(false);
    }
  }, [notify]);

  useEffect(()=>{
    fetchPeople();

  }, [ fetchPeople ]);

  useEffect(()=>{
    function addPerson(person: Person){
      setPeople(people=>([...people, person]));
      setMessage('');
    }

    function removePerson(personId: number) {
      setPeople(people=>people.filter(v=>v.id !== personId));
    }

    function updatePerson(updatedPerson: Person, personId: number) {
      setPeople(people=>{
        const index = people.findIndex(u=>u.id === personId);
        const updatedList = [...people];
        updatedList[index] = updatedPerson;
        return updatedList;
      });
    }

    peopleService.on('person-created', addPerson);
    peopleService.on('person-deleted', removePerson);
    peopleService.on('person-updated', updatePerson);

    return ()=>{
      peopleService.off('person-created', addPerson);
      peopleService.off('person-deleted', removePerson);
      peopleService.off('person-updated', updatePerson);
    }

  }, []);

  return [people, loading, message];
}
