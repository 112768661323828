
import { useEffect, useState } from 'react';

export default function useSearchFilter<T>(data: T[], keys: string, searchTerm: string) {
  const [searchResults, setSearchResults] = useState(data);

  useEffect(()=>{
    function applySearch() {
      const keysList = keys.split(/\s*,\s*/);
      setSearchResults(data.filter((item: any)=>{
        let matched = false;
        for(let i=0; i<keysList.length; i++){
          if(item[keysList[i]] && item[keysList[i]].toString().toLowerCase().includes(searchTerm.toLowerCase())){
            matched = true;
            break;
          }
        }

        return matched;
      }));
    }

    setTimeout(applySearch, 0);

  }, [data, keys, searchTerm])

  return searchResults;
}
