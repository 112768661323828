
import { Theme, createStyles } from '@material-ui/core/styles';

export default (theme: Theme) => createStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: '2px 4px 2px 4px'
  },
  input: {
    flex: 1,
    paddingLeft: 4,
    paddingRight: 4
  },
  iconButton: {
    padding: 10
  },
  divider: {
    height: 28,
    margin: 4
  }
});
